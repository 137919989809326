import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function PremiumOverlay({ onLogin, showAd = true, onRegister }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (onLogin && typeof onLogin === 'function') {
      onLogin();
    } else {
      navigate('/login');
    }
  };

  const handleRegister = () => {
    if (onRegister && typeof onRegister === 'function') {
      onRegister();
    } else {
      navigate('/register');
    }
  };

  return (
    <Paper
      elevation={5}
      sx={{
        p: 3,
        borderRadius: 2,
        position: 'relative',
        textAlign: 'center',
        maxWidth: 600,
        width: '100%',
        mx: 'auto',
        my: 'auto',
        bgcolor: 'rgba(26, 26, 36, 0.97)',
        backdropFilter: 'blur(8px)',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 20px ${theme.palette.primary.main}20`,
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '5px',
          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        }
      }}
    >
      <LockIcon 
        sx={{ 
          fontSize: 50,
          color: theme.palette.primary.main,
          mb: 1
        }} 
      />
      
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
        {"Premium Content"}
      </Typography>
      
      <Typography variant="body2" sx={{ mb: 3, maxWidth: 450, mx: 'auto' }}>
        {"This is premium content available to registered users. Sign in to access all premium ideas, rate them, and earn credits for your own submissions."}
      </Typography>
      
      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        spacing={2} 
        justifyContent="center"
        sx={{ mb: 3 }}
      >
        <Button 
          variant="contained" 
          color="primary" 
          size="medium"
          onClick={handleLogin}
          sx={{ 
            minWidth: 120,
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: '0.95rem',
            color: '#1A1A24',
          }}
        >
          Sign In
        </Button>
        
        <Button 
          variant="outlined" 
          color="primary"
          size="medium"
          onClick={handleRegister}
          sx={{ 
            minWidth: 120,
            textTransform: 'none',
            fontSize: '0.95rem',
            borderColor: 'primary.main',
            color: 'primary.main',
            '&:hover': {
              borderColor: 'primary.light',
              bgcolor: 'rgba(92, 255, 231, 0.1)',
            }
          }}
        >
          Register Now
        </Button>
      </Stack>
      
    </Paper>
  );
}

export default PremiumOverlay; 