import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Chip,
  useTheme,
  useMediaQuery,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import StarIcon from '@mui/icons-material/Star';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import PeopleIcon from '@mui/icons-material/People';
import RefreshIcon from '@mui/icons-material/Refresh';
import MobileBottomNavigation from './MobileBottomNavigation';

function Profile() {
  const { user, checkAuth, isPremium, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [scrapeLoading, setScrapeLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);
  
  // Format balance to show cents properly
  const formattedBalance = user?.balance 
    ? (Math.round(user.balance * 100) / 100).toFixed(2)
    : '0.00';
  
  const fetchUsers = useCallback(async () => {
    if (user?.id !== 1) return;
    
    setLoadingUsers(true);
    try {
      const response = await fetch('/api/users/users?sort=newest', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setUserList(data);
      } else {
        console.error('Failed to fetch users list');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoadingUsers(false);
    }
  }, [user]);

  useEffect(() => {
    fetchProfile();
    if (user?.id === 1) {
      fetchUsers();
    }
  }, [user, fetchUsers]);

  const fetchProfile = async () => {
    try {
      const response = await fetch('/api/users/me/profile', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEmail(data.email);
      }
    } catch (error) {
      setError('Failed to load profile');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await fetch('/api/users/me/profile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setSuccess('Profile updated successfully');
      } else {
        const data = await response.json();
        setError(data.detail || 'Failed to update profile');
      }
    } catch (error) {
      setError('Failed to update profile');
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      const response = await fetch('/api/users/me/password', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword
        })
      });

      if (response.ok) {
        setSuccess('Password updated successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const data = await response.json();
        setError(data.detail || 'Failed to update password');
      }
    } catch (error) {
      setError('Failed to update password');
    }
  };

  const handleBuyPremium = async () => {
    setPremiumLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch('/api/users/buy-premium', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message);
        // Refresh auth context to update premium status
        await checkAuth();
      } else {
        const data = await response.json();
        setError(data.detail || 'Failed to purchase premium');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setPremiumLoading(false);
    }
  };

  // Handle scrape function for admin
  const   handleScrape = async () => {
    setScrapeLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await fetch('/api/ideas/scrape', {
        method: 'POST',
      });
      
      if (response.ok) {
        setSuccess('Scraping started! New ideas will be available soon.');
        setTimeout(() => {
          setSuccess('');
        }, 15000);
      } else {
        setError('Failed to start scraping');
      }
    } catch (error) {
      console.error('Error forcing scrape:', error);
      setError('Failed to start scraping. Please try again.');
    } finally {
      setScrapeLoading(false);
    }
  };

  // Handle logout
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100vh'
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container 
      maxWidth="md" 
      className={isMobile ? "mobile-container" : ""}
      sx={{ 
        pt: isMobile ? 1 : 3,
        pb: isMobile ? (isStandalone 
          ? 'calc(env(safe-area-inset-bottom, 0px) + 30px)' 
          : 'calc(64px + env(safe-area-inset-bottom, 0px) + 30px)') : 3,
        px: isMobile ? 1 : 2,
        ...(isMobile && {
          // Allow normal scrolling
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: 'auto',
          minHeight: 'calc(100vh - env(safe-area-inset-bottom, 0px))',
          paddingTop: 'env(safe-area-inset-top, 0px)',
          position: 'relative',
          zIndex: 1
        })
      }}
    >

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <Paper 
        elevation={isMobile ? 0 : 3}
        sx={{ 
          p: isMobile ? 2 : 3,
          borderRadius: isMobile ? 0 : 2,
          mb: 3,
          backgroundColor: isMobile ? 'transparent' : 'background.paper',
        }}
      >
        

        <Box 
          sx={{ 
            mb: 4, 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between', 
            alignItems: isMobile ? 'flex-start' : 'center',
            backgroundColor: 'rgba(26, 26, 36, 0.1)',
            p: 2,
            borderRadius: 2
          }}
        >
          <Box sx={{ mb: isMobile ? 2 : 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AccountCircleIcon sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Account Status
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>
              Current Credits: <span style={{ color: theme.palette.primary.main }}>{formattedBalance}</span>
            </Typography>
          </Box>
          <Box>
            {isPremium ? (
              <Chip 
                icon={<StarIcon />} 
                label="Premium Account" 
                color="primary" 
                sx={{ fontWeight: 'bold' }}
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleBuyPremium}
                disabled
                // disabled={premiumLoading || (user?.balance < 10)}
                startIcon={<StarIcon />}
                sx={{
                  textTransform: 'none',
                  borderRadius: isMobile ? 50 : 4,
                }}
              >
                {premiumLoading ? 'Processing...' : 'Buy Premium (10 Credits)'}
              </Button>
            )}
          </Box>
        </Box>

        <Box 
          component="form" 
          onSubmit={handleUpdateProfile} 
          sx={{ 
            mb: 4,
            backgroundColor: 'rgba(26, 26, 36, 0.05)',
            p: 2,
            borderRadius: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <EmailIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Update Email
            </Typography>
          </Box>
          
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: isMobile ? 2 : 1,
              }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ 
              mt: 1,
              borderRadius: isMobile ? 50 : 4,
              textTransform: 'none',
              py: isMobile ? 1 : undefined
            }}
          >
            Update Email
          </Button>
        </Box>

        <Box 
          component="form" 
          onSubmit={handleUpdatePassword}
          sx={{ 
            mb: 4,
            backgroundColor: 'rgba(26, 26, 36, 0.05)',
            p: 2,
            borderRadius: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <KeyIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Change Password
            </Typography>
          </Box>
          
          <TextField
            fullWidth
            type="password"
            label="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            margin="normal"
            autoComplete="current-password"
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: isMobile ? 2 : 1,
              }
            }}
          />
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
            autoComplete="new-password"
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: isMobile ? 2 : 1,
              }
            }}
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
            autoComplete="new-password"
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: isMobile ? 2 : 1,
              }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ 
              mt: 1,
              borderRadius: isMobile ? 50 : 4,
              textTransform: 'none',
              py: isMobile ? 1 : undefined
            }}
          >
            Update Password
          </Button>
        </Box>

        <Box 
          sx={{ 
            mt: 2,
            backgroundColor: 'rgba(255, 0, 0, 0.05)',
            p: 2,
            borderRadius: 2
          }}
        >
          <Typography variant="h6" sx={{ color: 'error.main', fontWeight: 'bold', mb: 1 }}>
            Logout
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
            Sign out from your account
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            sx={{ 
              borderRadius: isMobile ? 50 : 4,
              textTransform: 'none',
              py: isMobile ? 1 : undefined
            }}
          >
            Logout
          </Button>
        </Box>
      </Paper>

      {/* Admin Section - Only visible to user with ID 1 */}
      {user?.id === 1 && (
        <Paper 
          elevation={isMobile ? 0 : 3}
          sx={{ 
            p: isMobile ? 2 : 3,
            borderRadius: isMobile ? 0 : 2,
            mb: 3,
            backgroundColor: isMobile ? 'transparent' : 'background.paper',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 2, 
            backgroundColor: 'rgba(25, 118, 210, 0.1)', 
            p: 2, 
            borderRadius: 2 
          }}>
            <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Admin Dashboard
            </Typography>
          </Box>
          
          {/* Scrape Button Section */}
          <Box sx={{ 
            mb: 3,
            p: 2,
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
            borderRadius: 2,
            border: '1px dashed',
            borderColor: 'success.main'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <RefreshIcon sx={{ mr: 1, color: 'success.main' }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Idea Scraper
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
              Trigger the scraper to collect new ideas from external sources
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleScrape}
                disabled={scrapeLoading}
                startIcon={<RefreshIcon />}
                sx={{ 
                  borderRadius: isMobile ? 50 : 4,
                  textTransform: 'none',
                  py: isMobile ? 1 : undefined
                }}
              >
                {scrapeLoading ? 'Scraping...' : 'Start Scraping'}
              </Button>
              {success && success.includes('Scraping started') && (
                <Alert severity="success" sx={{ flexGrow: 1 }}>
                  {success}
                </Alert>
              )}
            </Box>
          </Box>
          
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
            User Management
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={fetchUsers}
              startIcon={<RefreshIcon />}
              disabled={loadingUsers}
              sx={{ 
                textTransform: 'none',
                borderRadius: 2 
              }}
            >
              Refresh Users
            </Button>
          </Box>
          {loadingUsers ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <List sx={{ 
              bgcolor: 'background.paper', 
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}>
              {userList.length > 0 ? (
                userList.map((listUser, index) => (
                  <React.Fragment key={listUser.id}>
                    <ListItem 
                      alignItems="flex-start"
                      sx={{
                        flexDirection: isMobile ? 'column' : 'row',
                        py: 1.5
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{ component: 'div' }}
                        secondaryTypographyProps={{ component: 'div' }}
                        primary={
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            mb: isMobile ? 0.5 : 0
                          }}>
                            <Typography 
                              component="div" 
                              variant="body2" 
                              color="text.secondary"
                              sx={{ 
                                mb: 0.5,
                                fontWeight: 'medium',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Box
                                component="div"
                                sx={{
                                  mr: 1,
                                  px: 0.8,
                                  py: 0.2,
                                  borderRadius: 1,
                                  fontSize: '0.7rem',
                                  fontWeight: 'bold',
                                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                                  border: '1px solid',
                                  borderColor: 'primary.main',
                                  color: 'primary.main',
                                  display: 'inline-block'
                                }}
                              >
                                ID: {listUser.id}
                              </Box>
                            </Typography>
                            <Typography 
                              component="div" 
                              variant="subtitle1" 
                              fontWeight="bold"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {listUser.email}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <Box sx={{ 
                            mt: 0.5, 
                            display: 'flex', 
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: 1
                          }}>
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center'
                            }}>
                              <Typography 
                                component="div" 
                                variant="body2"
                                sx={{ mr: 0.5 }}
                              >
                                Credits:
                              </Typography>
                              <Chip
                                size="small"
                                label={(() => {
                                  // Format balance to 2 decimal places
                                  const formatBalance = (value) => {
                                    if (value === undefined || value === null) return '0.00';
                                    const numValue = typeof value === 'string' ? parseFloat(value) : value;
                                    if (isNaN(numValue)) return '0.00';
                                    return (Math.round(numValue * 100) / 100).toFixed(2);
                                  };
                                  
                                  // Primarily check for balance property as confirmed
                                  if (listUser.balance !== undefined) {
                                    return formatBalance(listUser.balance);
                                  }
          
                                  return '0.00';
                                })()}
                                color="primary"
                                variant="outlined"
                                sx={{ 
                                  fontSize: '0.7rem',
                                  height: 22,
                                  fontWeight: 'bold'
                                }}
                              />
                            </Box>
                            {listUser.is_premium && (
                              <Chip 
                                size="small"
                                icon={<StarIcon sx={{ fontSize: '0.8rem' }} />} 
                                label="Premium" 
                                color="primary" 
                                sx={{ height: 22, fontSize: '0.7rem' }}
                              />
                            )}
                          </Box>
                        }
                        sx={{
                          margin: 0
                        }}
                      />
                    </ListItem>
                    {index < userList.length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No users found" />
                </ListItem>
              )}
            </List>
          )}
        </Paper>
      )}

      {isMobile && <MobileBottomNavigation currentValue="profile" />}
    </Container>
  );
}

export default Profile; 