import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ideasViewed, setIdeasViewed] = useState(() => {
        const saved = localStorage.getItem('ideasViewed');
        return saved ? JSON.parse(saved) : [];
    });

    // Check auth status on mount and token change
    const checkAuth = async () => {
        try {
            // Get token from localStorage to include in header if available
            const token = localStorage.getItem('token');
            const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
            
            const response = await fetch('/api/auth/me', {
                headers,
                credentials: 'include', // Important for cookies
            });
            
            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
                setIsAuthenticated(true);
            } else {
                // If authentication fails, clear any stale tokens
                localStorage.removeItem('token');
                setUser(null);
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Auth check failed:', error);
            localStorage.removeItem('token');
            setUser(null);
            setIsAuthenticated(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        localStorage.setItem('ideasViewed', JSON.stringify(ideasViewed));
    }, [ideasViewed]);

    const login = async (email, password) => {
        try {
            const response = await axios.post('/api/auth/login', {
                email: email,
                password: password
            }, {
                withCredentials: true
            });
            
            if (response.data) {
                // Store the token in localStorage
                if (response.data.access_token) {
                    localStorage.setItem('token', response.data.access_token);
                }
                
                const userData = response.data.user;
                setUser(userData);
                setIsAuthenticated(true);
                return userData;
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await fetch('/api/auth/logout', {
                method: 'POST',
                credentials: 'include',
            });
        } catch (error) {
            console.error('Logout error:', error);
        }
        
        // Clear all auth-related data from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('ideasViewed');
        localStorage.removeItem('pendingIdeaId');
        
        // Reset all state
        setUser(null);
        setIsAuthenticated(false);
        setIdeasViewed([]);
    };

    // Add the idea to the viewed list for free users
    const trackIdea = (ideaId) => {
        if (!isAuthenticated && !ideasViewed.includes(ideaId)) {
            setIdeasViewed([...ideasViewed, ideaId]);
        }
    };

    // Check if user has reached free limit
    const hasReachedFreeLimit = () => {
        if (isAuthenticated) return false;
        return ideasViewed.length >= 5; // Should match FREE_MODE.MAX_IDEAS in backend
    };

    // Get updated user credits
    const refreshUserCredits = async () => {
        if (!isAuthenticated) return;
        
        try {
            const response = await fetch('/api/auth/me', {
                credentials: 'include',
            });
            
            if (response.ok) {
                const userData = await response.json();
                setUser(prevUser => ({
                    ...prevUser,
                    balance: userData.balance
                }));
                return userData.balance;
            }
        } catch (error) {
            console.error('Failed to refresh user credits:', error);
        }
    };

    // Spend credits for premium actions
    const spendCredits = async (amount, reason) => {
        if (!isAuthenticated || !user) {
            return { success: false, message: 'Not authenticated' };
        }

        if (user.balance < amount) {
            return { success: false, message: 'Insufficient credits' };
        }

        try {
            const response = await fetch('/api/users/spend-credits', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ amount, reason })
            });

            if (response.ok) {
                const result = await response.json();
                
                // Update user balance locally
                setUser(prevUser => ({
                    ...prevUser,
                    balance: result.balance
                }));
                
                return { success: true, balance: result.balance };
            } else {
                const error = await response.json();
                return { success: false, message: error.detail || 'Failed to spend credits' };
            }
        } catch (error) {
            console.error('Error spending credits:', error);
            return { success: false, message: 'Error processing request' };
        }
    };

    if (loading) {
        return null; // Or a loading spinner
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                login,
                logout,
                checkAuth,
                trackIdea,
                hasReachedFreeLimit,
                refreshUserCredits,
                spendCredits,
                isPremium: user?.is_premium || false,
                ideasViewed
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 