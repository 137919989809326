import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Card,
  IconButton,
  Dialog,
  DialogContent,
  Rating,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  Chip,
  Badge,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SendIcon from '@mui/icons-material/Send';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PremiumOverlay from './PremiumOverlay';
import MobileBottomNavigation from './MobileBottomNavigation';
import SwipeIcon from '@mui/icons-material/SwipeRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FilterListIcon from '@mui/icons-material/FilterList';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import HandshakeIcon from '@mui/icons-material/Handshake';
import InventoryIcon from '@mui/icons-material/Inventory2';
import BusinessIcon from '@mui/icons-material/Business';
import { submitIdea } from '../services/api';

function HomeCombined() {
  // Theme, navigation and auth/context hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, trackIdea, user, ideasViewed } = useAuth();

  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
    
    // Detect Safari
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const isSafariCheck = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/FxiOS/i);
    setIsSafari(isSafariCheck && !isIOSStandalone);
  }, []);

  // Calculate proper height based on environment
  const getCardHeight = () => {
    if (!isMobile) return 'calc(100vh - 160px)'; // Even smaller height for desktop 
    if (isStandalone) return 'calc(100vh - 130px)'; // Standalone: just account for header/navbar
    if (isSafari) return 'calc(100vh - 210px)';     // Safari: account for browser chrome
    return 'calc(100vh - 170px)';                   // Other browsers: medium adjustment
  };

  // Refs for scrolling and to keep track of one‑time initial fetch
  const submitSectionRef = useRef(null);
  const exploreSectionRef = useRef(null);
  const initialFetchDone = useRef(false);
  const cardRef = useRef(null);
  const SWIPE_THRESHOLD = 100;

  // UI state variables
  const [error, setError] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [activeMobileTab, setActiveMobileTab] = useState(0);
  const [showPremiumOverlay, setShowPremiumOverlay] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [noMoreIdeas, setNoMoreIdeas] = useState(false);
  const [idea, setIdea] = useState('');
  const [currentIdea, setCurrentIdea] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMatch, setShowMatch] = useState(false);
  const [rating, setRating] = useState(0);
  const [dragStart, setDragStart] = useState(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [tempSelectedCategories, setTempSelectedCategories] = useState([]);

  // --- URL / mobile tab management ---
  useEffect(() => {
    if (isMobile) {
      const params = new URLSearchParams(location.search);
      const tabParam = params.get('tab');
      setActiveMobileTab(tabParam === 'submit' ? 1 : 0);
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [location.search, isMobile]);

  useEffect(() => {
    if (isMobile) {
      const tabParam = activeMobileTab === 0 ? 'explore' : 'submit';
      window.history.replaceState(null, '', `/?tab=${tabParam}`);
    }
  }, [activeMobileTab, isMobile]);

  // --- Helper functions ---
  const shouldForceLogin = useCallback(() => {
    // Force login for non-authenticated users after viewing 5+ ideas
    return !isAuthenticated && ideasViewed.length >= 5;
  }, [isAuthenticated, ideasViewed]);

  const isAdmin = useCallback(() => {
    return user && user.id === 1;
  }, [user]);

  // --- Idea submission ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!idea.trim()) return;
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await submitIdea(idea);
      setIdea('');
      if (response?.idea_id) {
        if (!isAuthenticated) {
          localStorage.setItem('pendingIdeaId', response.idea_id);
        }
        navigate(`/progress/${response.idea_id}`);
      }
    } catch (err) {
      console.error('Failed to submit idea:', err);
      setError(err.message || 'Failed to submit your idea. Please try again.');
      setShowErrorAlert(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setShowErrorAlert(false);
  };

  // --- Fetch categories ---
  useEffect(() => {
    let mounted = true;
    fetch('/api/categories/')
      .then((res) => (res.ok ? res.json() : Promise.reject(res)))
      .then((data) => {
        if (mounted) setAvailableCategories(data);
      })
      .catch((err) => {
        if (mounted) console.error("Failed to fetch categories:", err);
      });
    return () => { mounted = false; };
  }, []);

  // --- Idea fetching logic ---
  const fetchNewIdea = useCallback(async (force = false) => {
    if (isLoading && !force) {
      return;
    }
    setIsLoading(true);
    setIsTransitioning(true);
    setError(null);
    setCurrentIdea(null);
    setNoMoreIdeas(false);
    setDragOffset(0);
    setDragStart(null);

    if (shouldForceLogin()) {
      setShowPremiumOverlay(true);
      setIsLoading(false);
      setIsTransitioning(false);
      return;
    }

    const params = new URLSearchParams();
    if (selectedCategories.length > 0) {
      params.append('categories', selectedCategories.join(','));
    }
    // (Assume viewed ideas can be appended if needed.)
    const url = `/api/swipes/random${params.toString() ? `?${params.toString()}` : ''}`;

    try {
      const response = await fetch(url, {
        headers: isAuthenticated
          ? { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
          : {}
      });
      if (response.status === 404) {
        setNoMoreIdeas(true);
        return;
      }
      if (response.status === 401) {
        setCurrentIdea({
          id: `sample-${Date.now()}`,
          title: "Sample Idea",
          content: "Sign in to see personalized ideas."
        });
        return;
      }
      if (!response.ok) {
        console.error(`Error response: ${response.status}`);
        setCurrentIdea({
          id: `error-${Date.now()}`,
          title: "Couldn't load idea",
          content: "Please try again."
        });
        return;
      }
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        console.error('Non-JSON response');
        setCurrentIdea({
          id: `error-${Date.now()}`,
          title: "Invalid response",
          content: "Unexpected response format."
        });
        return;
      }
      const data = await response.json();
      if (!data || !data.result) {
        console.error('Invalid data structure:', data);
        setCurrentIdea({
          id: `error-${Date.now()}`,
          title: "Invalid data",
          content: "Please try again."
        });
      } else {
        setCurrentIdea(data.result);
        if (data.idea_id) trackIdea(data.idea_id);
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setCurrentIdea({
        id: `error-${Date.now()}`,
        title: "Network error",
        content: "Check your connection and try again."
      });
    } finally {
      setIsLoading(false);
      setIsTransitioning(false);
    }
  }, [isLoading, isAuthenticated, selectedCategories, trackIdea, shouldForceLogin]);

  useEffect(() => {
    if (!initialFetchDone.current) {
      initialFetchDone.current = true;
      fetchNewIdea(true);
    }
  }, [fetchNewIdea]);

  
  // --- Swipe handling (left/right gestures) ---
  const handleSwipe = useCallback(async (liked) => {
    if (isTransitioning || !currentIdea || isLoading) return;
    setIsTransitioning(true);
    setDragOffset(0);
    setDragStart(null);

    if (isAuthenticated && currentIdea?.id) {
      try {
        const res = await fetch(`/api/swipes/${currentIdea.id}/swipe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ liked })
        });
        if (!res.ok) console.error('Swipe recording failed:', await res.text());
      } catch (err) {
        console.error('Error recording swipe:', err);
      }
    }
    if (liked) {
      setShowMatch(true);
    } else {
      if (shouldForceLogin()) {
        setShowPremiumOverlay(true);
      } else {
        setTimeout(() => fetchNewIdea(), 300);
      }
    }
    setIsTransitioning(false);
  }, [isTransitioning, isLoading, isAuthenticated, currentIdea, shouldForceLogin, fetchNewIdea]);

  const handleMatchClose = async () => {
    if (rating > 0 && currentIdea?.id && isAuthenticated) {
      try {
        await fetch(`/api/swipes/${currentIdea.id}/rate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ rating })
        });
      } catch (err) {
        console.error('Error submitting rating:', err);
      }
    }
    setShowMatch(false);
    setRating(0);
    if (shouldForceLogin()) {
      setShowPremiumOverlay(true);
    } else {
      fetchNewIdea();
    }
  };

  // --- Drag/gesture event handlers ---
  const getCardStyle = () => ({
    transform: dragOffset
      ? `translateX(${dragOffset}px) rotate(${dragOffset * 0.1}deg)`
      : 'translateX(0) scale(1)',
    transition: dragOffset === 0 ? 'transform 0.3s ease' : 'none'
  });

  const handleStart = useCallback((clientX) => {
    setDragStart(clientX);
    setDragOffset(0);
  }, []);

  const handleMove = useCallback((clientX) => {
    if (dragStart === null) return;
    setDragOffset(clientX - dragStart);
  }, [dragStart]);

  const handleEnd = useCallback(() => {
    if (dragStart === null) return;
    if (Math.abs(dragOffset) > SWIPE_THRESHOLD) {
      handleSwipe(dragOffset > 0);
    }
    setDragStart(null);
    setDragOffset(0);
  }, [dragStart, dragOffset, handleSwipe]);

  useEffect(() => {
    const card = cardRef.current;
    if (!card) return;

    const pointerStart = (e) => {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      handleStart(clientX);
    };
    const pointerMove = (e) => {
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      handleMove(clientX);
      if (dragStart !== null && Math.abs(clientX - dragStart) > 10) {
        e.preventDefault && e.preventDefault();
      }
    };
    const pointerEnd = () => handleEnd();

    card.addEventListener('touchstart', pointerStart, { passive: true });
    card.addEventListener('touchmove', pointerMove, { passive: false });
    card.addEventListener('touchend', pointerEnd);
    card.addEventListener('mousedown', pointerStart);
    window.addEventListener('mousemove', pointerMove);
    window.addEventListener('mouseup', pointerEnd);

    return () => {
      card.removeEventListener('touchstart', pointerStart);
      card.removeEventListener('touchmove', pointerMove);
      card.removeEventListener('touchend', pointerEnd);
      card.removeEventListener('mousedown', pointerStart);
      window.removeEventListener('mousemove', pointerMove);
      window.removeEventListener('mouseup', pointerEnd);
    };
  }, [handleStart, handleMove, handleEnd, dragStart]);

  useEffect(() => {
    setDragStart(null);
    setDragOffset(0);
  }, [currentIdea]);

  // --- Admin delete action ---
  const handleDelete = useCallback(async () => {
    if (!isAdmin() || !currentIdea || isLoading) return;
    setIsLoading(true);
    try {
      const res = await fetch(`/api/ideas/${currentIdea.id}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      if (res.ok) {
        fetchNewIdea();
      } else {
        alert('Failed to delete the idea. Please try again.');
      }
    } catch (err) {
      console.error('Error deleting idea:', err);
      alert('Failed to delete the idea. Check your connection.');
    } finally {
      setIsLoading(false);
    }
  }, [isAdmin, currentIdea, isLoading, fetchNewIdea]);

  // --- Section styling ---
  const sectionStyle = {
    width: '100%',
    ...(isMobile
      ? { minHeight: 'calc(100vh - 56px)', height: 'auto', m: 0, p: 0.5, borderRadius: 0 }
      : { height: 'calc(100vh - 40px)', maxHeight: 'calc(100vh - 40px)', display: 'flex', flexDirection: 'column', overflowY: 'hidden', p: 0, pt: 0, borderRadius: 2 })
  };

  // --- UI Rendering functions (submission, swipe, no ideas, mobile nav, etc.) ---
  const renderSubmissionSection = () => (
    <Box 
      ref={submitSectionRef} 
      className="idea-submit-section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: isMobile ? 'calc(100vh - 56px)' : '820px',
        maxHeight: isMobile ? 'auto' : '820px',
        pb: isMobile ? '68px' : 8,
        pt: isMobile ? 1 : 2,
        height: isMobile ? 'auto' : '820px',
        overflowY: isMobile ? 'visible' : 'auto',
        ...(isMobile && {
          justifyContent: 'space-between'
        }),
        ...((!isMobile) && {
          justifyContent: 'space-between',
          px: 3,
          py: 3,
          overflowY: 'hidden'
        })
      }}>
      <Box sx={{
        width: '100%',
        ...(isMobile && {
          flex: '0 0 auto'
        }),
        ...((!isMobile) && {
          mb: 2
        })
      }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          component="h2"
          align="center"
          gutterBottom
          color="primary"
          sx={{ fontSize: isMobile ? '1.5rem' : '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center', mb: !isMobile ? 2 : undefined }}
        >
          <LightbulbIcon sx={{ mr: 1 }} /> {isMobile ? "Share Your Idea" : "Submit Your Idea"}
        </Typography>
        {!isMobile && (
          <Typography variant="body1" align="center" sx={{ mb: 4, color: 'text.secondary', mx: 'auto', maxWidth: '100%' }}>
            Share your idea and we'll analyze its potential
          </Typography>
        )}
        {isMobile && (
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            mb: 2,
            justifyContent: 'center',
            width: '100%'
          }}>
            {[
              { 
                text: "A mobile app that \n[describe main purpose]\n\nfor \n[target audience]\n\nIt would solve \n[specific problem]\n\nby providing \n[key features]\n\nThe app would stand out because \n[unique value proposition]",
                icon: <SmartphoneIcon />, 
                label: "App Idea",
                description: "Tools, platforms, mobile solutions"
              },
              { 
                text: "A service that helps \n[target users]\n\nwith \n[specific need/challenge]\n\nIt would work by \n[brief explanation of process]\n\nand deliver value through \n[key benefits]\n\nThe business would generate revenue from \n[monetization approach]", 
                icon: <HandshakeIcon />, 
                label: "Service",
                description: "Marketplaces, subscriptions, on-demand"
              },
              { 
                text: "A physical product that \n[main function]\n\nfor \n[target market]\n\nIt solves \n[pain point]\n\nthrough \n[key features/technology]\n\nThe product would be better than existing solutions because \n[competitive advantage]", 
                icon: <InventoryIcon />, 
                label: "Product",
                description: "Devices, hardware, consumer goods"
              },
              { 
                text: "A business model for \n[industry/sector]\n\nthat creates value by \n[value proposition]\n\nThe company would serve \n[customer segments]\n\nthrough \n[delivery method]\n\nRevenue would come from \n[revenue streams]\n\nand growth could be achieved by \n[scaling strategy]", 
                icon: <BusinessIcon />, 
                label: "Business",
                description: "Revenue models, startups, ventures"
              }
            ].map((template, idx) => (
              <Paper
                key={idx}
                elevation={1}
                onClick={() => setIdea(template.text)}
                sx={{ 
                  width: 'calc(50% - 8px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  py: 1.5,
                  px: 1,
                  cursor: 'pointer',
                  borderRadius: 2,
                  transition: 'all 0.2s ease',
                  bgcolor: 'rgba(255,255,255,0.03)',
                  border: '1px solid',
                  borderColor: 'divider',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    bgcolor: 'rgba(92, 255, 231, 0.08)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                  },
                  '&:active': {
                    transform: 'translateY(0px)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    mb: 0.5,
                    color: 'secondary.main',
                    fontSize: '1.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {template.icon}
                </Box>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontWeight: 600,
                    fontSize: '0.8rem',
                    color: 'text.primary'
                  }}
                >
                  {template.label}
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    fontSize: '0.65rem',
                    color: 'text.secondary',
                    mt: 0.5,
                    textAlign: 'center',
                    lineHeight: 1.2
                  }}
                >
                  {template.description}
                </Typography>
                <Box 
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '3px',
                    bgcolor: 'secondary.main',
                    opacity: 0.5
                  }}
                />
              </Paper>
            ))}
          </Box>
        )}
      </Box>
      <Box sx={{ 
        width: '100%', 
        flex: isMobile ? '1 1 auto' : 'none',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <TextField
          multiline
          rows={8}
          placeholder={isMobile ? "Describe your idea in detail..." : "Describe your idea here..."}
          value={idea}
          onChange={(e) => setIdea(e.target.value)}
          fullWidth
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': { backgroundColor: 'rgba(26, 26, 36, 0.6)', borderRadius: isMobile ? 2 : 1 },
            mb: isMobile ? 2 : 3,
            flex: isMobile ? '1 1 auto' : 'none',
            ...(isMobile && { '& .MuiInputBase-input': { fontSize: '0.95rem', lineHeight: 1.5, padding: '12px 16px' } })
          }}
        />
        {isMobile && (
          <Typography variant="caption" align="right" sx={{ mb: 1, color: idea.length > 1500 ? 'error.main' : 'text.secondary', fontSize: '0.7rem', mr: 1 }}>
            {idea.length} characters
          </Typography>
        )}
        <Box textAlign="center" mt={isMobile ? 1 : 3} sx={{ mb: isMobile ? 2 : 0, flex: '0 0 auto' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!idea.trim() || isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={16} /> : <SendIcon fontSize="small" />}
            size="medium"
            sx={{ minWidth: isMobile ? 180 : 200, py: isMobile ? 1 : 1.5, fontSize: isMobile ? '0.9rem' : '1.1rem', borderRadius: isMobile ? 50 : 4, boxShadow: isMobile ? 3 : 1, textTransform: 'none' }}
          >
            {isSubmitting ? 'Analyzing...' : 'Submit Idea'}
          </Button>
        </Box>
      </Box>
      {isMobile ? (
        <Box sx={{ width: '100%', mt: 'auto', textAlign: 'center', opacity: 0.8, pb: 1, flex: '0 0 auto' }}>
          <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.65rem' }}>
            By submitting, you agree that your idea will be analyzed by AI
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mt: 'auto', pt: 4, pb: 2, textAlign: 'center' }}>
          <img src="/logo.png" alt="Hatoi.ai" style={{ width: '100%', maxWidth: '200px', height: 'auto', margin: '0 auto 1rem', display: 'block' }} />
          <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 600, mx: 'auto', fontSize: '0.75rem' }}>
            By submitting an idea, you acknowledge that it may be stored and analyzed by our AI.
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderNoIdeasMessage = () => (
    <Paper sx={{ p: isMobile ? 2 : 4, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: isMobile ? 1 : 2, backgroundColor: 'background.paper', border: '1px solid', borderColor: 'divider', flex: 1, minHeight: isMobile ? '450px' : '500px', maxWidth: 600, mx: 'auto' }}>
      <Typography variant={isMobile ? "subtitle1" : "h6"} color="primary" gutterBottom sx={{ mb: isMobile ? 1 : 2 }}>
        No More Ideas
      </Typography>
      <Typography variant={isMobile ? "body2" : "body1"} color="text.secondary" sx={{ mb: isMobile ? 1 : 2 }}>
        We've run out of ideas. Check back later or submit your own!
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={isMobile
          ? () => setActiveMobileTab(1)
          : () => submitSectionRef.current?.scrollIntoView({ behavior: 'smooth' })}
        startIcon={<LightbulbIcon />}
        size={isMobile ? "small" : "medium"}
        sx={{ mt: isMobile ? 1 : 2 }}
      >
        {isMobile ? "Submit" : "Submit Your Idea"}
      </Button>
    </Paper>
  );

  const renderSwipeSection = () => (
    <Box 
      ref={exploreSectionRef} 
      className="swipe-section"
      sx={{
        ...sectionStyle,
        ...(isMobile && { 
          display: 'flex', 
          flexDirection: 'column', 
          overflow: 'visible', 
          p: 0.5, 
          flexGrow: 1, 
          position: 'relative' 
        }),
        ...((!isMobile) && {
          px: 3,
          py: 3,
          display: 'flex',
          flexDirection: 'column'
        })
      }}>
      {isMobile && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, mb: 0.5 }}>
          <Typography variant="h6" component="div" sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            color: 'primary.main',
            fontSize: '1.15rem',
            lineHeight: 1,
            textAlign: 'center',
            letterSpacing: '0.02em'
          }}>
            🧠💡 <Box component="span" sx={{ ml: 1 }}>hatoi.io</Box>
            <Box component="span" sx={{
              ml: 1,
              fontSize: '0.6rem',
              bgcolor: 'rgba(0,0,0,0.04)',
              px: 0.6,
              py: 0.2,
              borderRadius: 1,
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              color: 'text.secondary',
              fontWeight: 'medium'
            }}>
              Beta
            </Box>
          </Typography>
        </Box>
      )}
      {!isMobile && (
        <Typography variant="h4" component="h2" align="center" gutterBottom color="primary" sx={{ fontSize: '1.75rem', mb: 1 }}>
          <SwipeIcon sx={{ mr: 1 }} /> Explore Ideas
        </Typography>
      )}
      {!isMobile && (
        <Typography variant="body1" align="center" sx={{ mb: 2, color: 'text.secondary', maxWidth: 600, mx: 'auto' }}>
          Swipe right on ideas you like, left on ones you don't.
        </Typography>
      )}
      {isLoading ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 600,
          height: isMobile ? '400px' : '300px',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress size={isMobile ? 40 : 60} color="primary" />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Finding ideas...
          </Typography>
        </Box>
      ) : noMoreIdeas ? (
        renderNoIdeasMessage()
      ) : showPremiumOverlay ? (
        <Paper sx={{
          p: isMobile ? 1 : 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: isMobile ? 1 : 2,
          backgroundColor: 'background.paper',
          border: '1px solid',
          borderColor: 'divider',
          flex: 1,
          minHeight: isMobile ? '450px' : '500px',
          maxWidth: 600,
          mx: 'auto',
          width: '100%'
        }}>
          <PremiumOverlay
            showAd={false}
            onLogin={() => navigate('/login')}
            onRegister={() => navigate('/register')}
          />
        </Paper>
      ) : currentIdea ? (
        <Box sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 600,
          mx: 'auto',
          height: getCardHeight(),
          flex: 'none',
          overflow: 'visible',
          touchAction: 'none',
        }}>
          {/* Swipe indicators */}
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            zIndex: 2,
            opacity: dragOffset < 0 ? Math.min(Math.abs(dragOffset) / 100, 1) : 0,
            transition: 'opacity 0.2s ease'
          }}>
            <CloseIcon fontSize="large" color="error" />
          </Box>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            zIndex: 2,
            opacity: dragOffset > 0 ? Math.min(dragOffset / 100, 1) : 0,
            transition: 'opacity 0.2s ease'
          }}>
            <FavoriteIcon fontSize="large" color="primary" />
          </Box>
          <Card ref={cardRef} sx={{
            p: isMobile ? 0 : 1,
            width: '100%',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderRadius: 2,
            cursor: 'grab',
            boxShadow: 3,
            opacity: isTransitioning ? 0 : 1,
            transform: getCardStyle().transform,
            transition: 'opacity 0.3s ease-out, transform 0.3s ease-out',
            visibility: isTransitioning && !currentIdea ? 'hidden' : 'visible',
            touchAction: 'none',
            zIndex: 1,
            border: '1px solid',
            borderColor: 'divider',
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(4px)',
            overflow: 'hidden',
            position: 'relative',
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid',
              borderColor: 'divider',
              pb: isMobile ? 0.25 : 0.5,
              pt: isMobile ? 0.75 : 0.5,
              px: isMobile ? 2 : 1,
              flex: '0 0 auto',
              height: isMobile ? 'auto' : '40px',
            }}>
              <Typography variant="h5" component="h3" sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: isMobile ? '1.2rem' : '1.25rem',
                flex: 1,
                textAlign: 'center'
              }}>
                {currentIdea.title || 'Idea Title'}
              </Typography>
              <IconButton onClick={() => {
                setTempSelectedCategories([...selectedCategories]);
                setFilterDialogOpen(true);
              }} color="primary" size="small" sx={{ ml: 1 }}>
                <Badge badgeContent={selectedCategories.length} color="secondary" invisible={selectedCategories.length === 0}>
                  <FilterListIcon fontSize="small" />
                </Badge>
              </IconButton>
            </Box>
            <Box sx={{
              flex: '1 1 auto',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              WebkitOverflowScrolling: 'touch',
              position: 'relative',
              height: isMobile ? 'calc(100% - 60px)' : 'calc(100% - 100px)',
            }}>
              <Box sx={{
                borderTop: '1px solid',
                borderColor: 'divider',
                pt: isMobile ? 1 : 0.5,
                pb: isMobile ? 1 : 0.5,
                px: isMobile ? 2 : 3,
                flex: isMobile ? '0 0 auto' : '0 0 20%',
                maxHeight: isMobile ? '30%' : '20%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    lineHeight: isMobile ? 1.3 : 1.6,
                    textAlign: 'center',
                    fontWeight: 500,
                    fontFamily: "'Roboto', 'Helvetica', sans-serif",
                    overflow: 'scroll',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 'auto',
                    fontSize: 'clamp(0.9rem, 1vw, 1rem)',
                  }}
                >
                  {currentIdea.summary}
                </Typography>
              </Box>
              <Box sx={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                flex: isMobile ? '1 1 auto' : '1 1 80%',
                overflow: 'hidden',
                display: 'flex',
                width: '100%',
                px: isMobile ? 3 : 3,
                pt: isMobile ? 2 : 0.5,
                pb: isMobile ? 2 : 0.5,
                position: 'relative'
              }}>
                {currentIdea.image_url ? (
                  <img src={currentIdea.image_url} alt={currentIdea.title || "Idea visualization"}
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: '100%',
                      borderRadius: 12,
                      objectFit: 'contain',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      padding: '8px'
                    }}
                    onError={(e) => {
                      e.target.style.display = 'none';
                      const parent = e.target.parentNode;
                      const brain = document.createElement('div');
                      brain.innerHTML = `<div style="text-align: center; opacity: 0.9;">
                        <span style="font-size: ${isMobile ? '4rem' : '4.5rem'};">🧠</span>
                      </div>`;
                      parent.appendChild(brain);
                    }}
                  />
                ) : (
                  <Typography variant="h1" sx={{ fontSize: { xs: '4rem', sm: '4.5rem' }, opacity: 0.9, color: 'primary.light', m: 2 }}>
                    🧠
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pt: isMobile ? 0.5 : 0.5,
              pb: isMobile ? 1 : 0.5,
              px: isMobile ? 2 : 0,
              gap: isMobile ? 5 : 6,
              borderTop: '1px solid',
              borderColor: 'divider',
              zIndex: 10,
              height: isMobile ? '60px' : '60px',
              flex: '0 0 auto',
              width: '100%',
              position: 'relative',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'background.paper',
              backdropFilter: 'blur(10px)'
            }}>
              <IconButton onClick={() => handleSwipe(false)} color="error" size={isMobile ? "medium" : "large"} sx={{
                border: '1px solid',
                borderColor: 'error.main',
                '&:hover': { backgroundColor: 'error.main', color: 'white' },
                p: isMobile ? 1.25 : 2,
                width: isMobile ? '48px' : 'auto',
                height: isMobile ? '48px' : 'auto'
              }}>
                <CloseIcon fontSize="medium" />
              </IconButton>
              {isAdmin() && (
                <IconButton onClick={handleDelete} color="warning" size={isMobile ? "medium" : "large"} sx={{
                  border: '1px solid',
                  borderColor: 'warning.main',
                  '&:hover': { backgroundColor: 'warning.main', color: 'white' },
                  p: isMobile ? 1.25 : 2,
                  width: isMobile ? '48px' : 'auto',
                  height: isMobile ? '48px' : 'auto'
                }}>
                  <DeleteForeverIcon fontSize="medium" />
                </IconButton>
              )}
              <IconButton onClick={() => handleSwipe(true)} color="primary" size={isMobile ? "medium" : "large"} sx={{
                border: '1px solid',
                borderColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.main', color: 'white' },
                p: isMobile ? 1.25 : 2,
                width: isMobile ? '48px' : 'auto',
                height: isMobile ? '48px' : 'auto'
              }}>
                <FavoriteIcon fontSize="medium" />
              </IconButton>
            </Box>
          </Card>
        </Box>
      ) : null}
      <Dialog open={showMatch && !isTransitioning} onClose={handleMatchClose} fullWidth maxWidth="sm"
        PaperProps={{ sx: { borderRadius: 3, backgroundColor: 'background.paper' } }}>
        <DialogContent sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {currentIdea?.title || 'Great Choice!'}
          </Typography>
          {isAuthenticated ? (
            <>
              <Typography variant="body1" sx={{ mb: 3 }}>
                This idea has been added to your list!
              </Typography>
              <Typography variant="body2" gutterBottom>
                How would you rate this idea?
              </Typography>
              <Rating value={rating} onChange={(e, newValue) => setRating(newValue)} size="large" sx={{ my: 2 }} />
            </>
          ) : (
            <Typography variant="body1" sx={{ mb: 3 }}>
              Sign in to save this idea to your profile and see more like it!
            </Typography>
          )}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Button variant="outlined" onClick={handleMatchClose}>Continue Browsing</Button>
            {isAuthenticated && currentIdea?.id && (
              <Button variant="contained" color="primary" component={Link} to={`/progress/${currentIdea.id}`} startIcon={<AssessmentIcon />}>
                View Analysis
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );

  const getHomeBottomNav = () => {
    const handleHomeClick = () => {
      setActiveMobileTab(0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleSubmitClick = () => {
      setActiveMobileTab(1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
      <Paper 
        className="mobile-bottom-nav"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          borderRadius: 0,
          boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
          paddingBottom: isStandalone
            ? 'env(safe-area-inset-bottom, 0px)'
            : 'calc(env(safe-area-inset-bottom, 0px) + 16px)'
        }}>
        <MobileBottomNavigation currentValue={activeMobileTab === 1 ? 'submit' : 'home'} onHomeClick={handleHomeClick} onSubmitClick={handleSubmitClick} />
      </Paper>
    );
  };

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [activeMobileTab, isMobile]);


  const FilterDialog = () => {
    if (!availableCategories.length) return null;
    const handleCloseFilterDialog = () => setFilterDialogOpen(false);
    const handleApplyFilters = () => {
      setSelectedCategories(tempSelectedCategories);
      setFilterDialogOpen(false);
      fetchNewIdea();
    };
    const handleClearFilters = () => setTempSelectedCategories([]);
    const handleToggleCategory = (category) => {
      setTempSelectedCategories(prev => prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]);
    };

    return (
      <Dialog open={filterDialogOpen} onClose={handleCloseFilterDialog} fullWidth maxWidth="sm"
        PaperProps={{ sx: { borderRadius: 2, p: 1 } }}>
        <DialogContent sx={{ p: 2 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Filter by Categories
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            Select categories to filter ideas:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3, maxHeight: '40vh', overflow: 'auto', p: 1 }}>
            {availableCategories.map(category => (
              <Chip key={category} label={category} clickable onClick={() => handleToggleCategory(category)}
                color={tempSelectedCategories.includes(category) ? 'secondary' : 'default'}
                variant={tempSelectedCategories.includes(category) ? 'filled' : 'outlined'}
                sx={{ mb: 0.5 }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
            <Button variant="text" onClick={handleClearFilters} disabled={!tempSelectedCategories.length}>
              Clear All
            </Button>
            <Button variant="contained" onClick={handleApplyFilters} color="primary">
              Apply Filters
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters={isMobile} sx={
      isMobile
        ? {
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 'env(safe-area-inset-top, 0px)',
            paddingBottom: isStandalone 
              ? 'calc(env(safe-area-inset-bottom, 0px) + 56px)' 
              : 'calc(64px + env(safe-area-inset-bottom, 0px) + 56px)',
            height: 'auto',
            minHeight: '100vh',
            position: 'relative',
            zIndex: 1
          }
        : { py: 2, px: 2, pt: 1 }
    }>
      <FilterDialog />
      {isMobile ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          p: 0,
          m: 0,
          minHeight: 'calc(100vh - env(safe-area-inset-bottom, 0px))'
        }}>
          {activeMobileTab === 0 ? renderSwipeSection() : renderSubmissionSection()}
          {getHomeBottomNav()}
        </Box>
      ) : (
        <Grid container spacing={0} alignItems="flex-start" position="relative" sx={{ height: 'calc(100vh - 40px)', overflow: 'hidden' }}>
          <Grid item xs={12} md={6} sx={{ pr: 0, height: '100%', overflow: 'auto' }}>{renderSubmissionSection()}</Grid>
          
          {/* Divider with "or" text */}
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '10%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '60px',
            }}
          >
            <Box
              sx={{
                width: '2px',
                height: '60px',
                mb: 2,
              }}
            />
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.paper',
                boxShadow: '0 0 15px rgba(255, 92, 141, 0.3)',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  color: 'secondary.main',
                  textTransform: 'uppercase',
                  fontSize: '0.8rem',
                }}
              >
                or
              </Typography>
            </Box>
            <Box
              sx={{
                width: '2px',
                height: '60px',
                mt: 2,
              }}
            />
          </Box>
          
          <Grid item xs={12} md={6} sx={{ pl: 0 }}>{renderSwipeSection()}</Grid>
        </Grid>
      )}
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleErrorClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default HomeCombined;