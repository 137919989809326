import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Paper,
  useTheme,
  useMediaQuery,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MobileBottomNavigation from './MobileBottomNavigation';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      await login(email, password);
      handleLoginSuccess();
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password. Please try again.');
    }
  };

  const handleLoginSuccess = () => {
    const pendingIdeaId = localStorage.getItem('pendingIdeaId');
    if (pendingIdeaId) {
        localStorage.removeItem('pendingIdeaId');
        navigate(`/progress/${pendingIdeaId}`);
    } else {
        navigate('/');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container 
      maxWidth={isMobile ? "xl" : "sm"} 
      disableGutters={isMobile}
      className={isMobile ? "mobile-container" : ""}
      sx={{ 
        ...(isMobile ? {
          p: 0,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 'env(safe-area-inset-top, 0px)',
          pb: 0,
          position: 'relative',
          zIndex: 1
        } : {
          pt: 4,
          pb: 4
        })
      }}
    >
      {isMobile ? (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
          pb: isStandalone 
            ? 'calc(env(safe-area-inset-bottom, 0px))' 
            : 'calc(64px + env(safe-area-inset-bottom, 0px))',
          pt: 0,
          px: 0,
          m: 0
        }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 2,
              pt: 4,
              borderRadius: 0,
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              mb: 3
            }}>
              <Typography 
                component="h1" 
                variant="h5" 
                color="primary"
                sx={{ 
                  mb: 1,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <LockIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                Sign In
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                align="center"
                sx={{ 
                  maxWidth: 300,
                  mb: 2 
                }}
              >
                Access your account to manage your ideas and preferences
              </Typography>
            </Box>

            <Box 
              component="form" 
              onSubmit={handleSubmit} 
              sx={{ 
                width: '100%',
                '& .MuiTextField-root': {
                  mb: 2
                }
              }}
            >
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
              
              {error && (
                <Typography 
                  color="error" 
                  sx={{ 
                    mt: 1, 
                    mb: 2,
                    fontSize: '0.875rem',
                    textAlign: 'center' 
                  }}
                >
                  {error}
                </Typography>
              )}
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ 
                  mt: 2, 
                  mb: 3,
                  py: 1.2,
                  fontSize: '0.9rem',
                  borderRadius: 8,
                  boxShadow: 3,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: '#1A1A24',
                }}
              >
                Sign In
              </Button>
              
              <Box sx={{ textAlign: 'center' }}>
                <Link 
                  href="/register" 
                  variant="body2"
                  sx={{ 
                    textDecoration: 'none',
                    color: 'primary.main',
                    fontWeight: 500,
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {"Don't have an account? Register"}
                </Link>
              </Box>
            </Box>
          </Paper>
          {isMobile && <MobileBottomNavigation currentValue="login" />}
        </Box>
      ) : (
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            borderRadius: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mb: 3
          }}>
            <Typography 
              component="h1" 
              variant="h4" 
              color="primary"
              sx={{ 
                mb: 1,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <LockIcon sx={{ mr: 1, fontSize: '2rem' }} />
              Sign In
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              align="center"
              sx={{ 
                maxWidth: 300,
                mb: 2 
              }}
            >
              Access your account to manage your ideas and preferences
            </Typography>
          </Box>

          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              width: '100%',
              '& .MuiTextField-root': {
                mb: 2
              }
            }}
          >
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            
            {error && (
              <Typography 
                color="error" 
                sx={{ 
                  mt: 1, 
                  mb: 2,
                  fontSize: '0.875rem',
                  textAlign: 'center' 
                }}
              >
                {error}
              </Typography>
            )}
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ 
                mt: 2, 
                mb: 3,
                py: 1.2,
                fontSize: '0.9rem',
                borderRadius: 8,
                boxShadow: 3,
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#1A1A24',
              }}
            >
              Sign In
            </Button>
            
            <Box sx={{ textAlign: 'center' }}>
              <Link 
                href="/register" 
                variant="body2"
                sx={{ 
                  textDecoration: 'none',
                  color: 'primary.main',
                  fontWeight: 500,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {"Don't have an account? Register"}
              </Link>
            </Box>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default Login; 