import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

function IntroTour() {
  const { isAuthenticated } = useAuth();
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [steps, setSteps] = useState([]);
  const introRef = useRef(null);

  useEffect(() => {
    // Only proceed for non-authenticated users who haven't seen the tour
    const tourShown = localStorage.getItem('hatoiTourShown');
    
    if (!isAuthenticated && !tourShown) {
      // Function to prepare and start the tour
      const prepareTour = () => {        
        // Ensure page is at the top before starting the tour
        window.scrollTo(0, 0);
        
        // Detect if user is on mobile
        const isMobile = window.innerWidth <= 768;
        
        // Check if key elements exist before starting tour
        const submitSection = document.querySelector('.idea-submit-section');
        const swipeSection = document.querySelector('.swipe-section');
        const bottomNav = document.querySelector('.mobile-bottom-nav');
        const submitButton = document.querySelector('.mobile-bottom-nav [data-value="submit"]') || 
                           document.querySelector('.mobile-bottom-nav a[href*="submit"]');
        
        const availableSteps = [
          {
            element: 'body',
            intro: "Welcome to hatoi.io! We're an AI Idea Processor to help bring your ideas to life.",
            position: 'center'
          }
        ];
        
        // Different steps based on device type
        if (isMobile) {
          // Mobile-specific steps
          if (swipeSection) {
            availableSteps.push({
              element: '.swipe-section',
              intro: "Swipe left or right to explore existing ideas. This is a great way to find inspiration.",
              position: 'top'
            });
          }
          
          // Target the Submit button specifically if found, otherwise fall back to the bottom nav
          if (submitButton) {
            availableSteps.push({
              element: submitButton,
              intro: "Tap 'Submit' in the bottom navigation to add your own idea. Our AI will analyze its potential.",
              position: 'bottom',
              tooltipClass: 'hatoi-intro-tooltip submit-tooltip-centered',
              highlightClass: 'submit-highlight-fix',
              tooltipPosition: 'center',
              disableInteraction: false
            });
          } else if (bottomNav) {
            availableSteps.push({
              element: '.mobile-bottom-nav',
              intro: "Tap 'Submit' in the bottom navigation to add your own idea. Our AI will analyze its potential.",
              position: 'top'
            });
          }
        } else {
          // Desktop-specific steps
          if (submitSection) {
            availableSteps.push({
              element: '.idea-submit-section',
              intro: "Enter your idea here and let our AI analyze its potential.",
              position: 'bottom'
            });
          }
          
          if (swipeSection) {
            availableSteps.push({
              element: '.swipe-section',
              intro: "Or browse through existing ideas to find inspiration.",
              position: 'top'
            });
          }
        }
        
        // Only start if we have at least one step beyond the welcome message
        if (availableSteps.length > 1) {
          setSteps(availableSteps);
          setStepsEnabled(true);
        }
      };
      
    setTimeout(prepareTour, 500);
      
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Cleanup previous instance if it exists
    if (introRef.current) {
      introRef.current.exit();
      introRef.current = null;
    }
    
    if (stepsEnabled && steps.length > 0) {
      // Check if intro.js is available globally
      if (typeof window.introJs === 'function') {
        try {
          // Save current scroll position
          const scrollPos = window.scrollY;
          
          // Create a new intro.js instance
          const intro = window.introJs();
          introRef.current = intro;
          
          // Configure the intro
          intro.setOptions({
            steps: steps,
            doneLabel: 'Get Started',
            nextLabel: 'Next',
            prevLabel: 'Back',
            showBullets: true,
            showProgress: true,
            exitOnOverlayClick: false,
            overlayOpacity: 0.7,
            tooltipClass: 'hatoi-intro-tooltip',
            highlightClass: 'hatoi-intro-highlight',
            scrollToElement: false,  // Disable auto-scrolling to elements
            scrollTo: 'tooltip', // Only scroll to show tooltip if needed
            scrollPadding: 0,  // No extra padding when scrolling
            // Force center alignment of tooltips with fixed position
            hintPosition: 'middle',
            // Override internal calculation of position for tooltips
            positionPrecedence: ['bottom', 'top', 'right', 'left']
          });
          
          // Custom hint placement for Submit button tooltip
          intro.onbeforechange(function(targetElement) {
            // Find the current step index
            const currentStepIndex = steps.findIndex(step => {
              // Match by element or intro text for Submit button
              return (targetElement === document.querySelector(step.element) || 
                     (step.intro && step.intro.includes('Submit')));
            });
            
            // If this is the Submit button step
            if (currentStepIndex >= 0 && steps[currentStepIndex].tooltipClass && 
                steps[currentStepIndex].tooltipClass.includes('submit-tooltip-centered')) {
              // Short delay to ensure DOM is ready
              setTimeout(() => {
                // Find the tooltip element and force center it
                const tooltip = document.querySelector('.submit-tooltip-centered');
                if (tooltip) {
                  tooltip.style.left = '50% !important';
                  tooltip.style.transform = 'translateX(-50%) !important';
                  tooltip.style.bottom = '84px !important';
                  tooltip.classList.remove('introjs-top-left-aligned');
                  tooltip.classList.remove('introjs-bottom-left-aligned');
                  tooltip.classList.add('introjs-bottom');
                }
              }, 50);
            }
            
            // Scroll back to top on each step change
            window.scrollTo(0, 0);
          });
          
          // Create a mutation observer to directly adjust the tooltip position
          // when it appears in the DOM with the wrong position
          const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
              if (mutation.type === 'childList') {
                // Look for tooltip elements added to the DOM
                const tooltips = document.querySelectorAll('.introjs-tooltip');
                tooltips.forEach(tooltip => {
                  // Check if this is a submit tooltip (based on text content)
                  const tooltipText = tooltip.querySelector('.introjs-tooltiptext');
                  if (tooltipText && tooltipText.textContent.includes('Submit')) {
                    // Directly adjust positioning
                    tooltip.style.cssText = 
                      "left: 50% !important; " +
                      "transform: translateX(-50%) !important; " +
                      "position: fixed !important; " +
                      "bottom: 84px !important; " + 
                      "display: block !important; " +
                      "opacity: 1 !important; " +
                      "margin-left: 0 !important;";
                    
                    // Adjust arrow position
                    const arrow = tooltip.querySelector('.introjs-arrow');
                    if (arrow) {
                      arrow.style.cssText = 
                        "left: 50% !important; " +
                        "transform: translateX(-50%) !important; " +
                        "display: inherit !important;";
                    }
                    
                    // Add class for CSS targeting
                    tooltip.classList.add('submit-tooltip-centered');
                  }
                });
              }
            }
          });
          
          // Start observing the document body for added tooltips
          observer.observe(document.body, { 
            childList: true, 
            subtree: true 
          });
          
          // Set up event handlers
          intro.onexit(() => {
            setStepsEnabled(false);
            localStorage.setItem('hatoiTourShown', 'true');
            introRef.current = null;
            observer.disconnect(); // Clean up observer
            
            // Restore original scroll position if any
            if (scrollPos > 0) {
              window.scrollTo(0, scrollPos);
            }
          });
          
          // Start the intro
          intro.start();
        } catch (err) {
          console.error('Failed to initialize intro.js:', err);
          setStepsEnabled(false);
        }
      } else {
        // If intro.js is not available, just mark it as shown so we don't keep trying
        console.warn('intro.js is not available, skipping tour');
        localStorage.setItem('hatoiTourShown', 'true');
        setStepsEnabled(false);
      }
    }
    
    // Cleanup function
    return () => {
      if (introRef.current) {
        try {
          introRef.current.exit();
        } catch (e) {
          // Ignore errors during cleanup
        }
        introRef.current = null;
      }
    };
  }, [stepsEnabled, steps]);

  return null;
}

export default IntroTour; 