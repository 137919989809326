import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  useTheme,
  useMediaQuery,
  InputAdornment,
  IconButton,
  Link
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MobileBottomNavigation from './MobileBottomNavigation';
import { useAuth } from '../contexts/AuthContext';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { login } = useAuth();
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);

  // Email validation
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  // Password validation
  const validatePassword = (password) => {
    // Only check if password has at least 6 characters
    return password.length >= 6;
  };

  // Handle email change with validation
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    
    if (newEmail && !validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  // Handle password change with validation
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    
    if (newPassword && !validatePassword(newPassword)) {
      setPasswordError('Password must be at least 6 characters.');
    } else {
      setPasswordError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    
    // Validate password strength
    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 6 characters.');
      return;
    }
    
    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        
        // Push successful registration event to dataLayer for GTM conversion tracking
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'event_new_sign_up',
        });
        
        // Auto-login the user
        try {
          await login(email, password);
          
          // Check if there's a pending idea to associate with the new account
          const pendingIdeaId = localStorage.getItem('pendingIdeaId');
          if (pendingIdeaId) {
            localStorage.removeItem('pendingIdeaId');
            navigate(`/progress/${pendingIdeaId}`);
          } else {
            navigate('/');
          }
        } catch (loginError) {
          console.error('Auto-login failed:', loginError);
          // If auto-login fails, still redirect user to login page
          navigate('/login');
        }
      } else {
        const error = await response.json();
        setError(error.detail);
      }
    } catch (error) {
      setError('Registration failed. Please try again.');
    }
  };

  // Toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  // Toggle confirm password visibility
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Container 
      maxWidth={isMobile ? "xl" : "sm"} 
      disableGutters={isMobile}
      className={isMobile ? "mobile-container" : ""}
      sx={{ 
        ...(isMobile ? {
          p: 0,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 'env(safe-area-inset-top, 0px)',
          pb: isStandalone 
            ? 'calc(env(safe-area-inset-bottom, 0px))' 
            : 'calc(64px + env(safe-area-inset-bottom, 0px))',
          pt: 0,
          position: 'relative',
          zIndex: 1
        } : {
          pt: 4,
          pb: 4
        })
      }}
    >
      {isMobile ? (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
          pb: isStandalone 
            ? 'calc(env(safe-area-inset-bottom, 0px))' 
            : 'calc(64px + env(safe-area-inset-bottom, 0px))',
          pt: 0,
          px: 0,
          m: 0
        }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 2,
              pt: 4,
              borderRadius: 0,
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              mb: 3
            }}>
              <Typography 
                component="h1" 
                variant="h5" 
                color="primary"
                sx={{ 
                  mb: 1,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <LockIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                Create Account
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                align="center"
                sx={{ 
                  maxWidth: 300,
                  mb: 2 
                }}
              >
                Join Hatoi to submit and track your ideas
              </Typography>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mt: 0, mb: 2, width: '100%' }}>
                {error}
              </Alert>
            )}

            <Box 
              component="form" 
              onSubmit={handleSubmit} 
              sx={{ 
                width: '100%',
                '& .MuiTextField-root': {
                  mb: 2
                }
              }}
            >
              <TextField
                required
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={handleEmailChange}
                error={!!emailError}
                helperText={emailError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="action" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
              <TextField
                required
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassword}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
              <TextField
                required
                fullWidth
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowConfirmPassword}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  }
                }}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ 
                  mt: 2, 
                  mb: 3,
                  py: 1.2,
                  fontSize: '0.95rem',
                  borderRadius: 8,
                  boxShadow: 3,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: '#1A1A24',
                }}
              >
                Create Account
              </Button>
              
              <Box sx={{ textAlign: 'center' }}>
                <Link 
                  href="/login" 
                  variant="body2"
                  sx={{ 
                    textDecoration: 'none',
                    color: 'primary.main',
                    fontWeight: 500,
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {"Already have an account? Sign In"}
                </Link>
              </Box>
            </Box>
          </Paper>
          {isMobile && <MobileBottomNavigation currentValue="login" />}
        </Box>
      ) : (
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            borderRadius: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mb: 3
          }}>
            <Typography 
              component="h1" 
              variant="h4" 
              color="primary"
              sx={{ 
                mb: 1,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <LockIcon sx={{ mr: 1, fontSize: '2rem' }} />
              Create Account
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              align="center"
              sx={{ 
                maxWidth: 300,
                mb: 2 
              }}
            >
              Join Hatoi to submit and track your ideas
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 0, mb: 2, width: '100%' }}>
              {error}
            </Alert>
          )}

          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              width: '100%',
              '& .MuiTextField-root': {
                mb: 2
              }
            }}
          >
            <TextField
              required
              fullWidth
              label="Email Address"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              required
              fullWidth
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowConfirmPassword}
                      edge="end"
                      tabIndex={-1}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ 
                mt: 2, 
                mb: 3,
                py: 1.2,
                fontSize: '0.95rem',
                borderRadius: 8,
                boxShadow: 3,
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#1A1A24',
              }}
            >
              Create Account
            </Button>
            
            <Box sx={{ textAlign: 'center' }}>
              <Link 
                href="/login" 
                variant="body2"
                sx={{ 
                  textDecoration: 'none',
                  color: 'primary.main',
                  fontWeight: 500,
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {"Already have an account? Sign In"}
              </Link>
            </Box>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default Register; 