import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton, 
  Tooltip,
  Menu,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
  Badge
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

function AppLayout({ children }) {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Always hide top bar on mobile devices
  const shouldHideTopBar = () => isMobile;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleForceScrape = async () => {
    try {
      const response = await fetch('/api/ideas/scrape', {
        method: 'POST',
      });
      if (response.ok) {
        alert('Scraping started!');
      }
    } catch (error) {
      console.error('Error forcing scrape:', error);
      alert('Failed to start scraping');
    }
    handleMenuClose();
  };

  const MenuItems = () => (
    <>
      <Button 
        color="inherit" 
        onClick={() => navigate('/')}
      >
        Home
      </Button>
      
      {isAuthenticated && (
        <>
          <Button 
            color="inherit" 
            onClick={() => navigate('/dashboard')}
          >
            Dashboard
          </Button>
          <Tooltip title="Profile">
            <IconButton 
              color="inherit" 
              onClick={() => navigate('/profile')}
            >
              {user?.balance ? (
                <Badge
                  badgeContent={(Math.round(user.balance * 100) / 100).toFixed(2)}
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#FFD700',
                      color: '#000',
                    }
                  }}
                >
                  <AccountCircleIcon />
                </Badge>
              ) : <AccountCircleIcon />}
            </IconButton>
          </Tooltip>
          {user?.id === 1 && (
            <Tooltip title="Force Scrape">
              <IconButton 
                color="inherit" 
                onClick={handleForceScrape}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {!isAuthenticated && (
        <>
          <Button 
            color="inherit"
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
          <Button 
            color="inherit"
            onClick={() => navigate('/register')}
          >
            Register
          </Button>
        </>
      )}
    </>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {!shouldHideTopBar() && (
        <AppBar position="static">
          <Toolbar>
            <Typography component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              🧠💡 <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>hatoi.io</Box>
              <Box 
                component="span" 
                sx={{ 
                  ml: 1, 
                  fontSize: '0.7rem', 
                  bgcolor: 'rgba(255,255,255,0.2)', 
                  px: 0.8, 
                  py: 0.2, 
                  borderRadius: 1,
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em'
                }}
              >
                Beta
              </Box>
            </Typography>
            {isMobile ? (
              <Box>
                <IconButton
                  color="inherit"
                  onClick={handleMenuOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      mt: 5,
                      backgroundColor: 'background.paper',
                      minWidth: 200,
                    }
                  }}
                >
                  <MenuItems />
                </Menu>
              </Box>
            ) : (
              <MenuItems />
            )}
          </Toolbar>
        </AppBar>
      )}

      <Container 
        component="main" 
        sx={{ 
          mt: shouldHideTopBar() ? 0 : 4, 
          mb: shouldHideTopBar() ? 0 : 4, 
          px: shouldHideTopBar() && isMobile ? 0 : 2,
          py: shouldHideTopBar() && isMobile ? 0 : 2,
          flex: 1,
        }}
      >
        {children}
      </Container>

      {!shouldHideTopBar() && (
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'background.paper' }}>
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 1 }}>
              © {new Date().getFullYear()} Hatoi
            </Typography>
          </Container>
        </Box>
      )}
    </Box>
  );
}

export default AppLayout; 