import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  Paper,
  Rating,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { formatDistanceToNow } from 'date-fns';
import { formatCurrency } from '../utils/format';
import { getButtonText, normalizeStatus, STATUS } from '../utils/status';

// Color scheme logic based on score
const scoreColorScheme = {
  success: {
    border: '#2e7d32',
    bg: 'rgba(46, 125, 50, 0.08)',
    text: '#fff',
    boxBg: '#2e7d32'
  },
  warning: {
    border: '#ed6c02',
    bg: 'rgba(237, 108, 2, 0.08)',
    text: '#fff',
    boxBg: '#ed6c02'
  },
  error: {
    border: '#d32f2f',
    bg: 'rgba(211, 47, 47, 0.08)',
    text: '#fff',
    boxBg: '#d32f2f'
  },
  default: {
    border: '#9e9e9e',
    bg: 'rgba(158, 158, 158, 0.08)',
    text: '#fff',
    boxBg: '#757575'
  }
};

const getScoreColor = (score) => {
  if (score >= 80) return 'success';
  if (score >= 60) return 'warning';
  return 'error';
};

function IdeaCard({ idea, onDelete, onImageClick, onUnlike, isPremium = false, currentTab = 0, userId = null, isLiked = false }) {
  const [isDeleting, setIsDeleting] = useState(false);
  
  // Create stats object from idea props, defaulting to empty values if not present
  const stats = {
    likes_count: idea.likes_count || 0,
    avg_rating: idea.avg_rating || 0,
    rating_count: idea.rating_count || 0
  };

  const colorScheme = idea.score !== null && idea.score !== undefined
    ? scoreColorScheme[getScoreColor(idea.score)]
    : scoreColorScheme.default;

  const normalizedStatus = normalizeStatus(idea.status);
  const isCompleted = normalizedStatus === STATUS.COMPLETED;
  
  // Determine if delete button should be visible
  // Show if user is admin (userId === 1) OR if user is in "My Ideas" tab (currentTab === 0)
  const showDeleteButton = userId === 1 || currentTab === 0;

  const handleDelete = async () => {
    const success = await onDelete(idea.idea_id, Boolean(idea.source));
    if (success) {
      setIsDeleting(true);
    }
  };

  const handleUnlike = async () => {
    if (onUnlike) {
      const success = await onUnlike(idea.idea_id);
      if (success) {
        setIsDeleting(true); // Reuse the deleting state to remove the card
      }
    }
  };

  if (isDeleting) {
    return null;
  }

  return (
    <Card 
      elevation={2}
      sx={{ 
        mb: 2,
        borderRadius: 2,
        position: 'relative',
        overflow: 'visible',
        '&:hover': {
          boxShadow: 6,
          transform: 'translateY(-2px)',
          transition: 'all 0.2s ease-in-out'
        }
      }}
    >
      <Box 
        sx={{ 
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          bgcolor: colorScheme.border,
        }} 
      />
      <CardContent sx={{ pl: 3 }}>
        {/* Title and Score Row */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Typography variant="h6" component="h2" sx={{ flex: 1 }}>
            {idea.title || idea.content}
          </Typography>
          <Box
            sx={{ 
              py: 0.75,
              px: 2,
              borderRadius: 2,
              bgcolor: colorScheme.boxBg,
              display: 'inline-flex',
              alignItems: 'center',
              flexShrink: 0,
              '&:hover': {
                boxShadow: 2,
                filter: 'brightness(1.1)'
              }
            }}
          >
            <Typography 
              sx={{ 
                fontWeight: 600,
                color: colorScheme.text,
                fontSize: { xs: '0.875rem', sm: '1rem' },
                letterSpacing: '0.5px'
              }}
            >
              {idea.score ?? 0}/100
            </Typography>
          </Box>
        </Box>

        {/* Meta Info Row */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: 2,
            mb: 3
          }}
        >
          {(isPremium && idea.cost !== undefined) && (
            <MetaInfo>Cost: {formatCurrency(idea.cost)}</MetaInfo>
          )}
          {idea.created_at && (
            <MetaInfo>
              {formatDistanceToNow(new Date(idea.created_at), { addSuffix: true })}
            </MetaInfo>
          )}

          {(userId === 1) && idea.source && (
            <MetaInfo>Source: {idea.source}</MetaInfo>
          )}
          {(userId === 1) && idea.user_email && (
            <MetaInfo>User: {idea.user_email}</MetaInfo>
          )}

          {/* Stats Row */}
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <FavoriteIcon fontSize="small" color="error" />
              <Typography variant="body2" color="text.secondary">
                {stats.likes_count}
              </Typography>
            </Box>
            {stats.rating_count > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Rating 
                  value={stats.avg_rating} 
                  readOnly 
                  size="small" 
                  precision={0.1}
                />
                <Typography variant="body2" color="text.secondary">
                  ({stats.rating_count})
                </Typography>
              </Box>
            )}
            {isLiked && idea.swipe_rating && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography variant="body2" color="text.secondary">
                  Your rating:
                </Typography>
                <Rating 
                  value={idea.swipe_rating} 
                  readOnly 
                  size="small"
                />
              </Box>
            )}
          </Stack>
        </Box>

        {/* Summary and Image Row */}
        {idea.summary && (
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              mb: 3,
              alignItems: 'stretch',
              height: { xs: 'auto', sm: '150px' },
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Paper 
              elevation={0}
              sx={{ 
                p: 2,
                flex: 1,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                overflow: 'auto',
                minHeight: { xs: '150px', sm: 'auto' },
                maxHeight: { sm: '150px' }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  lineHeight: 1.6,
                  color: 'text.primary'
                }}
              >
                {idea.summary}
              </Typography>
            </Paper>

            <ImageThumbnail 
              imageUrl={idea.image_url} 
              title={idea.title} 
              onClick={() => onImageClick(idea.image_url)}
            />
          </Box>
        )}

        {/* Actions */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 1,
            borderTop: 1,
            borderColor: 'divider',
            pt: 2,
            mt: 2
          }}
        >
          {isLiked && (
            <IconButton
              onClick={handleUnlike}
              color="error"
              size="small"
              title="Unlike"
              disabled={isDeleting}
            >
              <ThumbDownIcon fontSize="small" />
            </IconButton>
          )}
          {showDeleteButton && (
            <IconButton
              onClick={handleDelete}
              color="error"
              size="small"
              disabled={isDeleting}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          <Button
            component={Link}
            to={`/progress/${idea.idea_id}`}
            variant={isCompleted ? 'contained' : 'outlined'}
            size="small"
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              px: 3,
              opacity: isCompleted ? 1 : 0.7,
              '&:hover': {
                transform: isCompleted ? 'translateY(-1px)' : 'none',
                boxShadow: isCompleted ? 2 : 0
              }
            }}
          >
            {getButtonText(idea.status)}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

// Helper components
const MetaInfo = ({ children }) => (
  <Typography 
    color="textSecondary"
    sx={{ 
      fontSize: { xs: '0.875rem', sm: '1rem' },
      display: 'flex',
      alignItems: 'center',
      '&::after': {
        content: '""',
        display: { xs: 'none', sm: 'block' },
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        bgcolor: 'divider',
        ml: 2
      }
    }}
  >
    {children}
  </Typography>
);

const ImageThumbnail = ({ imageUrl, title, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      width: { xs: '100%', sm: '150px' },
      height: { xs: '200px', sm: '150px' },
      borderRadius: 2,
      overflow: 'hidden',
      cursor: 'pointer',
      flexShrink: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      border: '1px solid',
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {imageUrl ? (
      <img
        src={imageUrl}
        alt={title || "Idea visualization"}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          maxHeight: '100%'
        }}
      />
    ) : (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography color="textSecondary">🧠</Typography>
      </Box>
    )}
  </Box>
);

export default IdeaCard;