import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
  Paper
} from '@mui/material';
import IdeaCard from './IdeaCard';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CreateIcon from '@mui/icons-material/Create';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import RefreshIcon from '@mui/icons-material/Refresh';
import MobileBottomNavigation from './MobileBottomNavigation';

function Dashboard() {
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [userIdeas, setUserIdeas] = useState([]);
  const [scrapedIdeas, setScrapedIdeas] = useState([]);
  const [allUserIdeas, setAllUserIdeas] = useState([]);
  const [likedIdeas, setLikedIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated, isPremium } = useAuth();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('date');
  const sortOrder = 'desc';
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    
    // Check for tab parameter in URL
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    
    if (tabParam === 'scraped' && (user?.id === 1)) {
      setTab(2); // Set to Scraped Ideas tab
    } else if (tabParam === 'liked') {
      setTab(1); // Set to Liked Ideas tab
    } else if (tabParam === 'my') {
      setTab(0); // Set to My Ideas tab
    }
  }, [isAuthenticated, navigate, location, isPremium, user?.id]);

  const fetchIdeas = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }
    
    setLoading(true);
    try {
      // Always fetch user's own ideas
      const userResponse = await fetch(`/api/ideas/user/${user.id}`);
      if (userResponse.ok) {
        const userData = await userResponse.json();
        setUserIdeas(userData);
      }

      // Fetch liked ideas (from swipes)
      try {
        const likedResponse = await fetch('/api/swipes/liked');
        if (likedResponse.ok) {
          const likedData = await likedResponse.json();
          setLikedIdeas(likedData);
        } else {
          console.error('Failed to fetch liked ideas:', likedResponse.status);
          setLikedIdeas([]);
        }
      } catch (error) {
        console.error('Error fetching liked ideas:', error);
        setLikedIdeas([]);
      }

      // Only fetch admin data if user.id === 1
      if (user.id === 1) {
        const scrapedResponse = await fetch('/api/ideas/scraped');
        if (scrapedResponse.ok) {
          const scrapedData = await scrapedResponse.json();
          setScrapedIdeas(scrapedData);
        }

        const allIdeasResponse = await fetch('/api/ideas/all');
        if (allIdeasResponse.ok) {
          const allIdeasData = await allIdeasResponse.json();
          setAllUserIdeas(allIdeasData);
        }
      } else if (isPremium) {
        // Fetch scraped ideas for premium users too
        const scrapedResponse = await fetch('/api/ideas/scraped');
        if (scrapedResponse.ok) {
          const scrapedData = await scrapedResponse.json();
          setScrapedIdeas(scrapedData);
        }
      }
    } catch (error) {
      console.error('Error fetching ideas:', error);
    } finally {
      setLoading(false);
    }
  }, [user, isAuthenticated, isPremium]);

  useEffect(() => {
    fetchIdeas();
  }, [fetchIdeas]);

  const handleDelete = async (ideaId) => {
    const message = 'Are you sure you want to delete this idea? This action cannot be undone.';

    if (!window.confirm(message)) {
      return false;
    }

    try {
      const response = await fetch(`/api/ideas/${ideaId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Wait for animation to complete before updating state
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Update all idea lists that might contain this idea
        setUserIdeas(prev => prev.filter(idea => idea.idea_id !== ideaId));
        setScrapedIdeas(prev => prev.filter(idea => idea.idea_id !== ideaId));
        setAllUserIdeas(prev => prev.filter(idea => idea.idea_id !== ideaId));
        
        return true; // Return true for successful deletion
      } else {
        const data = await response.json();
        alert(data.detail || 'Failed to delete idea');
        return false;
      }
    } catch (error) {
      console.error('Error deleting idea:', error);
      alert('Error deleting idea');
      return false;
    }
  };

  const handleUnlike = async (ideaId) => {
    const message = 'Are you sure you want to unlike this idea?';

    if (!window.confirm(message)) {
      return false; // Return false if user cancels
    }

    try {
      const response = await fetch(`/api/swipes/${ideaId}/unlike`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        // Wait for animation to complete before updating state
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Only update liked ideas list
        setLikedIdeas(prev => prev.filter(idea => idea.idea_id !== ideaId));
        
        return true; // Return true for successful unlike
      } else {
        const data = await response.json();
        alert(data.detail || 'Failed to unlike idea');
        return false;
      }
    } catch (error) {
      console.error('Error unliking idea:', error);
      alert('Error unliking idea');
      return false;
    }
  };

  const sortIdeas = useCallback((ideas, sortBy, sortOrder) => {
    return [...ideas].sort((a, b) => {
      let comparison = 0;
      switch (sortBy) {
        case 'score':
          comparison = (b.score || 0) - (a.score || 0);
          break;
        case 'date':
          comparison = new Date(b.created_at) - new Date(a.created_at);
          break;
        default:
          comparison = 0;
      }
      return sortOrder === 'asc' ? -comparison : comparison;
    });
  }, []);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const ImageLightbox = () => (
    <Modal
      open={!!selectedImage}
      onClose={handleCloseImage}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={!!selectedImage}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90vw',
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 1,
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{
              maxWidth: '100%',
              maxHeight: '85vh',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Fade>
    </Modal>
  );

  const IdeasList = ({ ideas, isLiked = false, tabIndex }) => (
    <>
      <Box sx={{ 
        mb: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mr: 1, fontSize: isMobile ? '0.75rem' : '0.875rem' }}
        >
          Sort by:
        </Typography>
        <FormControl size="small" variant={isMobile ? "standard" : "outlined"}>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            sx={{ 
              minWidth: 100,
              fontSize: isMobile ? '0.8rem' : '0.875rem',
              '& .MuiSelect-select': {
                py: isMobile ? 0.5 : 1
              }
            }}
          >
            <MenuItem value="date">Most Recent</MenuItem>
            <MenuItem value="score">Highest Score</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      {ideas.length === 0 ? (
        <Box 
          sx={{
            textAlign: 'center',
            py: 6,
            px: 2,
            backgroundColor: 'rgba(26, 26, 36, 0.05)',
            borderRadius: 2
          }}
        >
          <Typography variant="body1" color="text.secondary">
            {isLiked ? "You haven't liked any ideas yet" : "No ideas found"}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {isLiked ? "Swipe right on ideas you find interesting" : "Submit your first idea to get started"}
          </Typography>
        </Box>
      ) : (
        sortIdeas(ideas, sortBy, sortOrder).map(idea => (
          <IdeaCard 
            key={idea.idea_id}
            idea={idea}
            onDelete={(ideaId) => handleDelete(ideaId)}
            onUnlike={isLiked ? handleUnlike : undefined}
            onImageClick={handleImageClick}
            isLiked={isLiked}
            isPremium={isPremium}
            isMobile={isMobile}
            currentTab={tabIndex}
            userId={user?.id}
          />
        ))
      )}
    </>
  );

  const UserIdeasSection = () => (
    <IdeasList ideas={userIdeas} tabIndex={0} />
  );

  const LikedIdeasSection = () => (
    <IdeasList ideas={likedIdeas} isLiked={true} tabIndex={1} />
  );

  const ScrapedIdeasSection = () => (
    <IdeasList ideas={scrapedIdeas} tabIndex={2} />
  );

  const AllIdeasSection = () => (
    <IdeasList ideas={allUserIdeas} tabIndex={3} />
  );

  // Replace the custom MobileBottomNavigation with the shared component
  function getDashboardBottomNav() {
    return <MobileBottomNavigation currentValue="dashboard" />;
  }

  if (loading) {
    return (
      <Box 
        sx={{ 
          height: '100vh', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container 
      maxWidth="md" 
      className={isMobile ? "mobile-container" : ""}
      sx={{ 
        pt: isMobile ? 1 : 4,
        pb: isMobile ? (isStandalone 
          ? 'calc(env(safe-area-inset-bottom, 0px))' 
          : 'calc(64px + env(safe-area-inset-bottom, 0px))') : 4,
        px: isMobile ? 1 : 2,
        ...(isMobile && {
          // Allow normal scrolling
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: 'auto',
          minHeight: 'calc(100vh - env(safe-area-inset-bottom, 0px))',
          paddingTop: 'env(safe-area-inset-top, 0px)',
          position: 'relative',
          zIndex: 1
        })
      }}
    >
      <Box sx={{
        // Remove flex properties and allow normal scrolling
        overflow: 'visible',
        // No need for additional bottom padding as it's handled by the container
        paddingBottom: 0
      }}>
        <Paper 
          elevation={isMobile ? 0 : 3}
          sx={{ 
            p: isMobile ? 0 : 2,
            borderRadius: isMobile ? 0 : 2,
            backgroundColor: isMobile ? 'transparent' : 'background.paper',
          }}
        >
          <Box 
            sx={{ 
              borderBottom: 1,
              borderColor: 'divider',
              mb: 3,
              mt: isMobile ? 1 : 1,
              backgroundColor: isMobile ? theme.palette.background.default : 'transparent',
              borderRadius: isMobile ? 2 : 0,
              boxShadow: isMobile ? '0 1px 3px rgba(0,0,0,0.05)' : 'none',
              px: isMobile ? 0.5 : 0,
              py: isMobile ? 0.5 : 0,
              position: isMobile ? 'sticky' : 'static',
              top: 0,
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Tabs 
              value={tab}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons={true}
              allowScrollButtonsMobile
              centered={!isMobile}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: isMobile ? '0.75rem' : '0.95rem',
                  py: isMobile ? 0.5 : 2,
                  minHeight: isMobile ? 48 : 'auto',
                  transition: 'all 0.2s ease',
                },
                '& .Mui-selected': {
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                },
                '& .MuiTabs-indicator': {
                  height: isMobile ? 3 : 2,
                  borderRadius: '3px 3px 0 0',
                  backgroundColor: theme.palette.primary.main,
                },
                '& .MuiTabs-scrollButtons': {
                  color: 'primary.main',
                },
                '& .MuiTabScrollButton-root': {
                  width: isMobile ? 28 : 40,
                  opacity: 0.8,
                }
              }}
            >
              <Tab 
                label={isMobile ? "My Ideas" : "My Ideas"} 
                icon={isMobile ? <CreateIcon fontSize="small" /> : null}
                iconPosition="top"
                sx={{ 
                  borderRadius: isMobile ? '8px 8px 0 0' : 0,
                  mx: isMobile ? 0.5 : 0,
                  minHeight: isMobile ? 70 : 'auto',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                  }
                }}
              />
              <Tab 
                label={isMobile ? "Liked" : "Liked Ideas"} 
                icon={isMobile ? <FavoriteIcon fontSize="small" /> : null}
                iconPosition="top"
                sx={{ 
                  borderRadius: isMobile ? '8px 8px 0 0' : 0,
                  mx: isMobile ? 0.5 : 0,
                  minHeight: isMobile ? 70 : 'auto',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                  }
                }}
              />
              {(user?.id === 1) && (
                <Tab 
                  label={isMobile ? "Scraped" : "Scraped Ideas"} 
                  icon={isMobile ? <RefreshIcon fontSize="small" /> : null}
                  iconPosition="top"
                  sx={{ 
                    borderRadius: isMobile ? '8px 8px 0 0' : 0,
                    mx: isMobile ? 0.5 : 0,
                    minHeight: isMobile ? 70 : 'auto',
                    '&.Mui-selected': {
                      color: theme.palette.primary.main,
                    }
                  }}
                />
              )}
              {user?.id === 1 && (
                <Tab 
                  label={isMobile ? "All" : "All Ideas"} 
                  icon={isMobile ? <AllInclusiveIcon fontSize="small" /> : null}
                  iconPosition="top"
                  sx={{ 
                    borderRadius: isMobile ? '8px 8px 0 0' : 0,
                    mx: isMobile ? 0.5 : 0,
                    minHeight: isMobile ? 70 : 'auto',
                    '&.Mui-selected': {
                      color: theme.palette.primary.main,
                    }
                  }}
                />
              )}
            </Tabs>
          </Box>

          <Box sx={{ px: isMobile ? 1 : 0 }}>
            {tab === 0 && <UserIdeasSection />}
            {tab === 1 && <LikedIdeasSection />}
            {tab === 2 && (user?.id === 1) && <ScrapedIdeasSection />}
            {tab === 3 && (user?.id === 1) && <AllIdeasSection />}
          </Box>
        </Paper>

        <ImageLightbox />
      </Box>

      {isMobile && getDashboardBottomNav()}
    </Container>
  );
}

export default Dashboard; 