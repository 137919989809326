// frontend/src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Register from './components/Register';
import Login from './components/Login';
import { AuthProvider } from './contexts/AuthContext';
import AppLayout from './components/AppLayout';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import IdeaProgress from './components/IdeaProgress';
import Profile from './components/Profile';
import HomeCombined from './components/HomeCombined';
import IntroTour from './components/IntroTour';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AppLayout>
          <Routes>
            <Route path="/" element={<HomeCombined />} />
            <Route path="/register" element={<Register />} />
            <Route path="/progress/:ideaId" element={<IdeaProgress />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
          <IntroTour />
        </AppLayout>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;