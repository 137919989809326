import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Paper, Button, Tabs, Tab, CircularProgress, Switch, FormControlLabel, Grid, Modal, TextField, MenuItem, Checkbox, FormGroup, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AnalysisIcon from '@mui/icons-material/Search';
import BuildIcon from '@mui/icons-material/Build';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme, useMediaQuery } from '@mui/material';
import MobileBottomNavigation from './MobileBottomNavigation';

// Add color scheme logic at the top
const scoreColorScheme = {
  success: {
    border: '#2e7d32',
    bg: 'rgba(46, 125, 50, 0.08)',
    text: '#fff',
    boxBg: '#2e7d32'
  },
  warning: {
    border: '#ed6c02',
    bg: 'rgba(237, 108, 2, 0.08)',
    text: '#fff',
    boxBg: '#ed6c02'
  },
  error: {
    border: '#d32f2f',
    bg: 'rgba(211, 47, 47, 0.08)',
    text: '#fff',
    boxBg: '#d32f2f'
  }
};

const getScoreColor = (score) => {
  if (score >= 80) return 'success';
  if (score >= 60) return 'warning';
  return 'error';
};

function IdeaProgress() {
  const { ideaId } = useParams();
  const navigate = useNavigate();;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated, user } = useAuth();
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [buildPlan, setBuildPlan] = useState(null);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [showOriginal, setShowOriginal] = useState(false);
  const [userOwnsIdea, setUserOwnsIdea] = useState(false);
  const [isClaimingIdea, setIsClaimingIdea] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);
  
  // Scroll to top when tab changes
  useEffect(() => {
    // Small delay to ensure content has rendered
    const timeoutId = setTimeout(() => {
      // Force scroll to top - needed for some mobile browsers
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 150); // Slightly longer delay to ensure rendering completes
    
    return () => clearTimeout(timeoutId);
  }, [activeTab]);
  
  // Modal states
  const [techTalentModalOpen, setTechTalentModalOpen] = useState(false);
  const [projectBoardModalOpen, setProjectBoardModalOpen] = useState(false);
  const [launchStrategyModalOpen, setLaunchStrategyModalOpen] = useState(false);
  const [updatesModalOpen, setUpdatesModalOpen] = useState(false);
  
  // Form states for the tech talent modal
  const [jobDescription, setJobDescription] = useState('');
  const [jobBudget, setJobBudget] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [preferredPlatform, setPreferredPlatform] = useState('fiverr');
  const [isSubmittingJob, setIsSubmittingJob] = useState(false);

  // Form states for the launch strategy modal
  const [targetMarket, setTargetMarket] = useState('');
  const [launchGoals, setLaunchGoals] = useState([]);
  const [launchTimeline, setLaunchTimeline] = useState('3months');
  const [isGeneratingStrategy, setIsGeneratingStrategy] = useState(false);
  
  // Form states for project updates modal
  const [updateFrequency, setUpdateFrequency] = useState('weekly');
  const [notificationMethod, setNotificationMethod] = useState('email');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [enabledUpdates, setEnabledUpdates] = useState({
    progressReminders: true,
    milestoneAlerts: true,
    resourceSuggestions: false,
    marketUpdates: false
  });


  // Form states for project board modal
  const [projectBoardPlatform, setProjectBoardPlatform] = useState('jira');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isCreatingBoard, setIsCreatingBoard] = useState(false);

  // Try to link the idea to the current user if they're authenticated
  const updateIdeaUser = useCallback(async () => {
    if (!isAuthenticated || !ideaId || userOwnsIdea || isClaimingIdea) return;
    
    // Check if this is the pending idea stored in localStorage
    const pendingIdeaId = localStorage.getItem('pendingIdeaId');
    if (pendingIdeaId === ideaId) {
      localStorage.removeItem('pendingIdeaId');
    }
    
    setIsClaimingIdea(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/ideas/${ideaId}/user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        setUserOwnsIdea(true);
        // Reload the idea data after claiming it
        const ideaResponse = await fetch(`/api/ideas/progress/${ideaId}`);
        if (ideaResponse.ok) {
          const updatedIdea = await ideaResponse.json();
          setIdea(updatedIdea);
        }
      } else if (response.status === 403) {
        // If we get a 403 Forbidden, it means the idea belongs to another user
        // Set userOwnsIdea to true to prevent further claim attempts
        setUserOwnsIdea(true);
      }
    } catch (error) {
      console.error('Failed to update idea user:', error);
    } finally {
      setIsClaimingIdea(false);
    }
  }, [isAuthenticated, ideaId, userOwnsIdea, isClaimingIdea, setIdea]);

  // Fetch progress effect
  useEffect(() => {
    if (!ideaId) {
      setError('No idea ID provided');
      setLoading(false);
      return;
    }

    let pollingInterval = null;

    const fetchProgress = async () => {
      try {
        const response = await fetch(`/api/ideas/progress/${ideaId}`);
        if (response.ok) {
          const data = await response.json();
          setIdea(data);
          setLastUpdated(new Date());
          
          // Check if the current user owns this idea
          if (isAuthenticated && data.user_id) {
            setUserOwnsIdea(true);
          } else if (isAuthenticated && !data.user_id && !userOwnsIdea) {
            // If user is logged in, idea has no owner, and we haven't tried to claim it yet
            await updateIdeaUser();
          }
          
          const status = data.status?.toLowerCase();

          if (status === 'rejected') {
            setError('Idea was rejected');
            // Stop polling if idea is rejected
            if (pollingInterval) {
              clearInterval(pollingInterval);
            }
          } else if (status === 'completed') {
            setIsAnalyzing(false);
            // Stop polling when idea is completed
            if (pollingInterval) {
              clearInterval(pollingInterval);
            }
          } else {
            // Set analyzing state based on status
            setIsAnalyzing(status === 'processing' || status === 'queued');
          }
        } else {
          setError('Failed to fetch idea progress');
          // Stop polling on error
          if (pollingInterval) {
            clearInterval(pollingInterval);
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred while fetching idea progress');
        // Stop polling on error
        if (pollingInterval) {
          clearInterval(pollingInterval);
        }
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchProgress();
    
    // Set up polling every 5 seconds if the idea is being analyzed
    pollingInterval = setInterval(fetchProgress, 5000);
    
    // Clean up the interval when component unmounts
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [ideaId, isAuthenticated, updateIdeaUser, userOwnsIdea, buildPlan]);

  const handleBuildPlan = async () => {
    if (buildPlan) {
      setActiveTab(1);
      return;
    }

    setIsGeneratingPlan(true);
    try {
      // Show appropriate message based on whether we're generating or retrieving
      const loadingMessage = document.getElementById('buildPlanLoadingMessage');
      if (loadingMessage) {
        loadingMessage.innerText = 'Generating build plan...';
      }
      
      // Generate a new build plan via POST
      const response = await fetch(`/api/ideas/${ideaId}/build-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setBuildPlan(data);
        setActiveTab(1);
      } else {
        throw new Error('Failed to generate build plan');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate build plan. Please try again.');
    } finally {
      setIsGeneratingPlan(false);
    }
  };

  const handleOpenTechTalentModal = () => {
    // Pre-populate job description based on idea and build plan
    let description = `Project: ${idea.title}\n\n`;
    
    if (buildPlan?.mvp_scope) {
      description += `Scope: ${buildPlan.mvp_scope}\n\n`;
    }
    
    if (buildPlan?.tech_stack) {
      const stack = [];
      // Keep full tech stack descriptions for the job description
      if (buildPlan.tech_stack.frontend) stack.push(`Frontend: ${buildPlan.tech_stack.frontend.join(', ')}`);
      if (buildPlan.tech_stack.backend) stack.push(`Backend: ${buildPlan.tech_stack.backend.join(', ')}`);
      if (buildPlan.tech_stack.database) stack.push(`Database: ${buildPlan.tech_stack.database.join(', ')}`);
      
      if (stack.length > 0) {
        description += `Tech Stack: \n${stack.join('\n')}\n\n`;
      }
    }
    
    description += "I'm looking for a developer to help me build out this idea. Please let me know if you have experience with these technologies and your availability.";
    
    // Reset form fields
    setJobDescription(description);
    setJobBudget('');
    
    // Pre-select skills from tech stack if available
    if (buildPlan?.tech_stack) {
      // Use the same skill extraction logic as in getSkillOptions
      const extractFirstTerm = (skill) => {
        const withoutParentheses = skill.split('(')[0].trim();
        return withoutParentheses.split(/[\s,;:]+/)[0].trim();
      };
      
      const preselectedSkills = [];
      
      // Take first 3 frontend skills if available
      if (buildPlan.tech_stack.frontend && Array.isArray(buildPlan.tech_stack.frontend)) {
        const frontendSkills = buildPlan.tech_stack.frontend.map(extractFirstTerm);
        preselectedSkills.push(...frontendSkills.slice(0, 3));
      }
      
      // Take first 2 backend skills if available
      if (buildPlan.tech_stack.backend && Array.isArray(buildPlan.tech_stack.backend) && preselectedSkills.length < 5) {
        const backendSkills = buildPlan.tech_stack.backend.map(extractFirstTerm);
        const remainingSlots = 5 - preselectedSkills.length;
        preselectedSkills.push(...backendSkills.slice(0, remainingSlots));
      }
      
      // Remove duplicates and empty strings
      setSelectedSkills([...new Set(preselectedSkills)].filter(skill => skill.length > 0));
    } else {
      // Clear selected skills if no tech stack
      setSelectedSkills([]);
    }
    
    setTechTalentModalOpen(true);
  };
  
  const handleSubmitTechJob = async () => {
    setIsSubmittingJob(true);
    
    try {
      // If the selected platform is Fiverr, redirect to the Fiverr affiliate link
      if (preferredPlatform === 'fiverr') {
        // Simulate a brief loading period before redirecting to Fiverr
        setTimeout(() => {
          setIsSubmittingJob(false);
          setTechTalentModalOpen(false);
          
          // Open Fiverr affiliate link in a new tab
          window.open('https://go.fiverr.com/visit/?bta=1106227&nci=17037', '_blank');
          
          alert('You\'re being redirected to Fiverr to post your job!');
        }, 1500);
        return;
      }
      
      // For other platforms, proceed with existing logic
      setTimeout(() => {
        // For now, we'll just simulate a success after 2 seconds
        setIsSubmittingJob(false);
        setTechTalentModalOpen(false);
        
        // Here you would normally show a success toast or modal
        alert('Your job has been posted successfully! You will receive notifications when freelancers respond.');
      }, 2000);
    } catch (error) {
      console.error('Error submitting job:', error);
      setIsSubmittingJob(false);
      alert('There was an error posting your job. Please try again.');
    }
  };
  
  // Skills options for the tech talent modal - dynamically generated based on tech stack if available
  const getSkillOptions = () => {
    // If we have a build plan with a tech stack, extract skills from there
    if (buildPlan?.tech_stack) {
      const techStackSkills = [];
      
      // Extract frontend skills
      if (buildPlan.tech_stack.frontend && Array.isArray(buildPlan.tech_stack.frontend)) {
        techStackSkills.push(...buildPlan.tech_stack.frontend);
      }
      
      // Extract backend skills
      if (buildPlan.tech_stack.backend && Array.isArray(buildPlan.tech_stack.backend)) {
        techStackSkills.push(...buildPlan.tech_stack.backend);
      }
      
      // Extract database skills
      if (buildPlan.tech_stack.database && Array.isArray(buildPlan.tech_stack.database)) {
        techStackSkills.push(...buildPlan.tech_stack.database);
      }
      
      // Extract infrastructure skills if they exist
      if (buildPlan.tech_stack.infrastructure && Array.isArray(buildPlan.tech_stack.infrastructure)) {
        techStackSkills.push(...buildPlan.tech_stack.infrastructure);
      }
      
      // If we found skills in the tech stack, clean them up and return
      if (techStackSkills.length > 0) {
        // Process each skill to extract just the first word/term
        const cleanedSkills = techStackSkills.map(skill => {
          // First, handle any parenthetical text
          const withoutParentheses = skill.split('(')[0].trim();
          
          // Then get just the first term (could be compound like React.js)
          // We'll consider a term to be continuous text without spaces/commas/etc.
          const firstTerm = withoutParentheses.split(/[\s,;:]+/)[0].trim();
          
          return firstTerm;
        });
        
        // Remove duplicates and filter out empty strings
        return [...new Set(cleanedSkills)].filter(skill => skill.length > 0);
      }
    }
    
    // Default generic skills for any idea (shorter, more focused list)
    return [
      'Web Development', 'Mobile Development', 'UI/UX Design', 
      'Backend Development', 'Frontend Development', 'Full Stack Development',
      'Database Design', 'DevOps', 'Cloud Architecture',
      'AI/Machine Learning', 'Product Management', 'QA & Testing'
    ];
  };
  
  // Get the current skill options based on the build plan
  const skillOptions = getSkillOptions();
  
  const handleSkillToggle = (skill) => {
    const currentIndex = selectedSkills.indexOf(skill);
    const newSkills = [...selectedSkills];
    
    if (currentIndex === -1) {
      newSkills.push(skill);
    } else {
      newSkills.splice(currentIndex, 1);
    }
    
    setSelectedSkills(newSkills);
  };

  const handleOpenUpdatesModal = () => {
    setUpdatesModalOpen(true);
  };
  
  
  const handleUpdateToggle = (update) => {
    setEnabledUpdates(prev => ({
      ...prev,
      [update]: !prev[update]
    }));
  };

  const handleOpenProjectBoardModal = () => {
    // Pre-populate information based on idea
    const name = idea?.title || '';
    const description = `Implementation plan for: ${name}\n\n${buildPlan?.mvp_scope || ''}`;
    
    setProjectName(name);
    setProjectDescription(description);
    setProjectBoardModalOpen(true);
  };
  
  const handleCreateProjectBoard = async () => {
    setIsCreatingBoard(true);
    
    try {
      // Here you would make an API call to your backend to integrate with the project management tool
      
      setTimeout(() => {
        // For now, we'll just simulate a success after 2 seconds
        setIsCreatingBoard(false);
        setProjectBoardModalOpen(false);
        
        // Here you would normally show a success toast or modal
        alert('Your project has been set up! You will receive an email with access details.');
      }, 2000);
    } catch (error) {
      console.error('Error creating project board:', error);
      setIsCreatingBoard(false);
      alert('There was an error setting up your project board. Please try again.');
    }
  };

  const handleOpenLaunchStrategyModal = () => {
    // Pre-populate from idea data
    if (idea?.market_potential) {
      const marketText = idea.market_potential.toLowerCase();
      if (marketText.includes('b2b') || marketText.includes('business-to-business')) {
        setTargetMarket('b2b');
      } else if (marketText.includes('b2c') || marketText.includes('consumer')) {
        setTargetMarket('b2c');
      }
    }
    
    setLaunchStrategyModalOpen(true);
  };
  
  const handleGenerateStrategy = async () => {
    setIsGeneratingStrategy(true);
    
    try {
      // Instead of simulating API call, we'll prepare to send an email notification
      // Collect relevant data from the form
      const strategyRequest = {
        ideaId: ideaId,
        ideaTitle: idea?.title || 'Untitled Idea',
        targetMarket,
        launchGoals,
        launchTimeline,
        userEmail: user?.email || 'No email provided',
        userId: user?.id || 'Anonymous user',
        timestamp: new Date().toISOString()
      };
      
      console.log('Strategy request data to be sent to Hubspot:', strategyRequest);
      
      // In a real implementation, you would make an API call to your backend
      // which would then use Hubspot's API to create a contact/send an email
      
      setTimeout(() => {
        setIsGeneratingStrategy(false);
        setLaunchStrategyModalOpen(false);
        
        // Show success message to the user
        alert('Thank you for your interest in our Launch Strategy service! Our team will contact you shortly to discuss your custom strategy.');
      }, 1500);
    } catch (error) {
      console.error('Error generating strategy:', error);
      setIsGeneratingStrategy(false);
      alert('There was an error processing your request. Our team has been notified.');
    }
  };
  
  const handleLaunchGoalToggle = (goal) => {
    setLaunchGoals(prev => {
      if (prev.includes(goal)) {
        return prev.filter(g => g !== goal);
      } else {
        return [...prev, goal];
      }
    });
  };
  
  // Handler for Connect with Investors button
  const handleConnectWithInvestors = () => {
    // Collect user and idea data to be sent to Hubspot
    const investorData = {
      ideaId: ideaId,
      ideaTitle: idea?.title || 'Untitled Idea',
      ideaScore: idea?.score || 0,
      userEmail: user?.email || 'No email provided',
      userId: user?.id || 'Anonymous user',
      requestType: 'Investor Connection',
      timestamp: new Date().toISOString()
    };
    
    console.log('Investor connection request data to be sent to Hubspot:', investorData);
    
    // In a real implementation, you would make an API call to your backend
    // which would then use Hubspot's API to create a contact/send an email
    
    alert('Thank you for your interest in connecting with investors! Our team will contact you shortly to discuss your funding needs.');
  };
  
  // Handler for Get Founder Mentorship button
  const handleGetFounderMentorship = () => {
    // Collect user and idea data to be sent to Hubspot
    const mentorshipData = {
      ideaId: ideaId,
      ideaTitle: idea?.title || 'Untitled Idea',
      ideaScore: idea?.score || 0,
      userEmail: user?.email || 'No email provided',
      userId: user?.id || 'Anonymous user',
      requestType: 'Founder Mentorship',
      timestamp: new Date().toISOString()
    };
    
    console.log('Founder mentorship request data to be sent to Hubspot:', mentorshipData);
    
    // In a real implementation, you would make an API call to your backend
    // which would then use Hubspot's API to create a contact/send an email
    
    alert('Thank you for your interest in founder mentorship! Our team will contact you shortly to connect you with an experienced mentor.');
  };

  // Show error if there is one
  if (error) {
    return (
      <Box sx={{ pb: isMobile ? 8 : 0 }}>
        {!isMobile && (
          <Button
            onClick={() => navigate('/')}
            startIcon={<HomeIcon />}
            sx={{ 
              borderRadius: 2,
              px: 2.5,
              py: 1,
              mb: 3,
              boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
              transition: 'all 0.2s ease',
              borderColor: 'primary.light',
              '&:hover': {
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                transform: 'translateY(-2px)'
              }
            }}
            variant="outlined"
            color="primary"
          >
            Home
          </Button>
        )}
        
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            textAlign: 'center'
          }}
        >
          <Typography 
            variant="h6" 
            color="primary.main"
            gutterBottom
            sx={{ mb: 2 }}
          >
            {idea?.title || 'Invalid Idea'}
          </Typography>
          
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            We couldn't process this submission as it doesn't appear to be a concrete idea or innovation.
          </Typography>
          
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.9rem',
              bgcolor: 'background.default',
              p: 2,
              borderRadius: 1
            }}
          >
            Tip: Try submitting a specific product, service, or innovation idea. Include details about what it does and how it works.
          </Typography>
        </Paper>
        {isMobile && <MobileBottomNavigation currentValue="progress" />}
      </Box>
    );
  }

  // Render loading state
  if (loading || !idea) {
    return (
      <Box sx={{ 
        width: '100%', 
        mt: 4, 
        pb: isMobile ? (isStandalone 
          ? 0 
          : 'calc(64px)') : 0 
      }}>
        <Typography align="center" gutterBottom variant="h6">
          Loading idea progress...
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CircularProgress size={40} />
        </Box>
        <Typography align="center" variant="body2" color="text.secondary">
          Please wait while we fetch your idea details.
        </Typography>
        {isMobile && <MobileBottomNavigation currentValue="progress" />}
      </Box>
    );
  }

  return (
    <Box 
      className={isMobile ? "mobile-container" : ""}
      sx={{ 
        pb: isMobile ? (isStandalone 
          ? 'calc(env(safe-area-inset-bottom, 0px))' 
          : 'calc(64px + env(safe-area-inset-bottom, 0px))') : 0,
        ...(isMobile && {
          // Allow normal scrolling
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: 'auto',
          minHeight: 'calc(100vh - env(safe-area-inset-bottom, 0px))',
          paddingTop: 'env(safe-area-inset-top, 0px)',
          position: 'relative',
          zIndex: 1
        })
      }}
    >


      {/* Main content in tabs with improved styling for mobile */}
      <Box 
        sx={{ 
          borderBottom: 1,
          borderColor: 'divider',
          mb: 3,
          mt: isMobile ? 1 : 1,
          backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.01)' : 'transparent',
          borderRadius: isMobile ? 2 : 0,
          boxShadow: isMobile ? '0 1px 3px rgba(0,0,0,0.05)' : 'none',
          px: isMobile ? 0.5 : 0,
          py: isMobile ? 0.5 : 0,
        }}
      >
        <Tabs 
          value={activeTab} 
          onChange={(e, v) => {
            setActiveTab(v);
          }}
          variant={isMobile ? "fullWidth" : "standard"}
          scrollButtons={false}
          allowScrollButtonsMobile={false}
          centered={!isMobile}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 'medium',
              fontSize: isMobile ? '0.7rem' : '0.95rem',
              py: isMobile ? 0.5 : 2,
              px: isMobile ? 1 : 3,
              minHeight: isMobile ? 60 : 'auto',
              transition: 'all 0.2s ease',
              minWidth: isMobile ? 'auto' : 80,
            },
            '& .Mui-selected': {
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            },
            '& .MuiTabs-indicator': {
              height: isMobile ? 3 : 2,
              borderRadius: '3px 3px 0 0',
              backgroundColor: theme.palette.primary.main,
            },
            '& .MuiTabs-scrollButtons': {
              color: 'primary.main',
            },
            '& .MuiTabScrollButton-root': {
              width: isMobile ? 20 : 40,
              opacity: 0.8,
            },
            ...(isMobile && {
              '& .MuiTabs-flexContainer': {
                width: '100%',
                justifyContent: 'space-between'
              },
              '& .MuiTab-iconWrapper': {
                marginBottom: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }
            })
          }}
        >
          {/* Analysis Tab */}
          <Tab 
            label={isMobile ? "Analysis" : "🔍 Analysis"} 
            icon={isMobile ? <AnalysisIcon fontSize="small" /> : null}
            iconPosition="top"
            sx={{ 
              borderRadius: isMobile ? '8px 8px 0 0' : 0,
              mx: isMobile ? 0 : 0.5,
              minHeight: isMobile ? 60 : 'auto',
              '&.Mui-selected': {
                color: theme.palette.primary.main,
              },
              ...(isMobile && {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flex: 1
              })
            }}
          />

          {/* Build Plan Tab */}
          {Number(idea?.score) >= 50 && (
            <Tab 
              label={isMobile ? "Build" : 
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  🛠️ Build Plan
                  {isGeneratingPlan && <CircularProgress size={16} sx={{ ml: 1 }} />}
                </Box>
              }
              icon={isMobile ? 
                (isGeneratingPlan ? <CircularProgress size={14} /> : <BuildIcon fontSize="small" />) 
                : null}
              iconPosition="top"
              sx={{ 
                borderRadius: isMobile ? '8px 8px 0 0' : 0,
                mx: isMobile ? 0 : 0.5,
                minHeight: isMobile ? 60 : 'auto',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                ...(isMobile && {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  flex: 1
                })
              }}
              onClick={!isAuthenticated ? () => {
                setActiveTab(0);
              } : (!buildPlan ? handleBuildPlan : undefined)}
            />
          )}

          {/* Implementation Tab - NEW */}
          {Number(idea?.score) >= 50 && buildPlan && (
            <Tab 
              label={isMobile ? "Implement" : "🚀 Implementation"}
              icon={isMobile ? 
                <Box component="span" sx={{ fontSize: '1.2rem' }}>🚀</Box> 
                : null}
              iconPosition="top"
              sx={{ 
                borderRadius: isMobile ? '8px 8px 0 0' : 0,
                mx: isMobile ? 0 : 0.5,
                minHeight: isMobile ? 60 : 'auto',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                ...(isMobile && {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  flex: 1
                })
              }}
              onClick={!isAuthenticated ? () => {
                setActiveTab(0);
              } : undefined}
            />
          )}
          
        </Tabs>
      </Box>

      {/* Non-logged-in users trying to access restricted tabs */}
      {!isAuthenticated && activeTab !== 0 && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            textAlign: 'center'
          }}
        >
          <LockIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
          <Typography variant="h5" color="primary.main" gutterBottom>
            Feature Locked
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Please create an account to access all features and get the full analysis of your idea.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="primary"
              component={Link}
              to="/register"
              sx={{ fontWeight: 'bold' }}
            >
              Register
            </Button>
            <Button 
              variant="outlined" 
              color="primary"
              component={Link}
              to="/login"
            >
              Login
            </Button>
          </Box>
        </Paper>
      )}

      {/* Show loading state for analysis */}
      {isAnalyzing && (
        <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2,
            my: 4 
          }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress 
                size={60}
                sx={{
                  animation: 'pulse 1.5s ease-in-out infinite',
                  '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.5 },
                    '100%': { opacity: 1 },
                  }
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" color="text.secondary">
                  {idea.progress ? `${Math.round(idea.progress * 100)}%` : 'AI'}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h5" color="primary.main" fontWeight="bold">
              Analyzing your idea...
            </Typography>
            <Typography color="text.secondary" sx={{ textAlign: 'center', maxWidth: 500 }}>
              Our AI is carefully evaluating your idea. This typically takes about a minute. 
              The page will automatically update when the analysis is complete.
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
              Last updated: {lastUpdated.toLocaleTimeString()}
            </Typography>
          </Box>
        </Paper>
      )}

      {activeTab === 0 && !isAnalyzing && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          {/* Toggle for Original Content - Only visible for premium users */}
          {user?.id === 1 && (
            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOriginal}
                    onChange={(e) => setShowOriginal(e.target.checked)}
                    color="primary"
                  />
                }
                label="Show Original Submission"
              />
            </Box>
          )}

          {/* Original Content Section - Only visible for premium users who toggled it on */}
          {user?.id === 1 && showOriginal && (
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                color="primary.main"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.4rem' },
                  fontWeight: 600
                }}
              >
                Original Submission
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor: 'background.default',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <Typography 
                  variant="body1"
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    lineHeight: 1.6
                  }}
                >
                  {idea?.content || 'No content available'}
                </Typography>
              </Paper>
            </Box>
          )}

          {/* Summary Section */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: 'flex-start'
          }}>
            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h6" 
                color="primary.main"
                gutterBottom
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.4rem' },
                  fontWeight: 600,
                  textAlign: 'center'
                }}
              >
                Summary
              </Typography>
              
              {isAuthenticated ? (
                <Typography 
                  variant="body1"
                  sx={{ whiteSpace: 'pre-wrap' }}
                >
                  {idea.summary}
                </Typography>
              ) : (
                <>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      whiteSpace: 'pre-wrap',
                      mb: 3,
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {idea.summary}
                  </Typography>
                  
                  <Paper 
                    elevation={2}
                    sx={{
                      p: 3,
                      mt: 2,
                      borderRadius: 2,
                      bgcolor: 'primary.light',
                      color: 'primary.contrastText',
                      textAlign: 'center'
                    }}
                  >
                    <LockIcon sx={{ fontSize: 32, mb: 1 }} />
                    <Typography variant="h6" gutterBottom>
                      Create an Account to View Full Analysis
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Sign up for free to see the complete AI analysis of your idea, including market potential, originality, and technical feasibility.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }}>
                      <Button 
                        variant="contained" 
                        color="primary"
                        component={Link}
                        to="/register"
                        sx={{ 
                          fontWeight: 'bold',
                          px: 3,
                          color: '#1A1A24',
                        }}
                      >
                        Register
                      </Button>
                      <Button 
                        variant="outlined" 
                        component={Link}
                        to="/login"
                        sx={{ 
                          borderColor: 'primary.contrastText',
                          color: 'primary.contrastText',
                          bgcolor: 'rgba(0, 0, 0, 0.05)',
                          '&:hover': {
                            borderColor: 'primary.contrastText',
                            bgcolor: 'rgba(0, 0, 0, 0.15)',
                          }
                        }}
                      >
                        Login
                      </Button>
                    </Box>
                  </Paper>
                </>
              )}
            </Box>

            {/* Image Section */}
            {idea?.image_url && (
              <Box sx={{ 
                width: { xs: '100%', md: '250px' },
                minWidth: { md: '250px' },
                ml: { md: 'auto' },
                flexShrink: 0
              }}>
                <Box
                  sx={{
                    width: '100%',
                    aspectRatio: '1',
                    borderRadius: 2,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                  }}
                >
                  <img 
                    src={idea.image_url} 
                    alt={idea.title || "Idea illustration"}
                    loading="lazy"
                    onError={(e) => {
                      console.error(`Failed to load image: ${idea.image_url}`, e);
                      e.target.style.display = 'none';
                      e.target.nextSibling.style.display = 'block';
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography 
                    variant="h1" 
                    sx={{ 
                      fontSize: { xs: '3rem', sm: '4rem' },
                      display: 'none'
                    }}
                  >
                    🧠
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Score Section - Updated styling */}
          {idea.score !== null && (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Box
                sx={{ 
                  py: 1.5,
                  px: 4,
                  borderRadius: 2,
                  bgcolor: scoreColorScheme[getScoreColor(idea.score)].boxBg,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    boxShadow: 2,
                    filter: 'brightness(1.1)'
                  }
                }}
              >
                <Typography 
                  variant="h4" 
                  sx={{ 
                    fontWeight: 'bold',
                    color: scoreColorScheme[getScoreColor(idea.score)].text
                  }}
                >
                  {idea.score}
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ 
                    ml: 1,
                    color: scoreColorScheme[getScoreColor(idea.score)].text,
                    fontWeight: 'medium'
                  }}
                >
                  /100
                </Typography>
              </Box>
            </Box>
          )}

          {/* Only show detailed analysis for logged in users */}
          {isAuthenticated && (
            <>
              {/* Analysis Sections */}
              <Grid container spacing={3} sx={{ mt: 3 }}>
                {/* Originality Section */}
                {idea.originality && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        height: '100%',
                        bgcolor: 'background.default',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        color="primary.main"
                        gutterBottom
                        sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                      >
                        Originality
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                        {idea.originality}
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {/* Market Potential Section */}
                {idea.market_potential && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        height: '100%',
                        bgcolor: 'background.default',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        color="primary.main"
                        gutterBottom
                        sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                      >
                        Market Potential
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                        {idea.market_potential}
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {/* Technical Feasibility Section */}
                {idea.technical_feasibility && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        height: '100%',
                        bgcolor: 'background.default',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        color="primary.main"
                        gutterBottom
                        sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                      >
                        Technical Feasibility
                      </Typography>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                        {idea.technical_feasibility}
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {/* Recommendations Section */}
                {idea.recommendations && (
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        height: '100%',
                        bgcolor: 'background.default',
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        color="primary.main"
                        gutterBottom
                        sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                      >
                        Recommendations
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          whiteSpace: 'pre-wrap',
                          '& ul, & ol': { mt: 0, mb: 0 }, // Remove margin from lists
                          '& p:first-of-type': { mt: 0 }, // Remove margin from first paragraph
                        }}
                      >
                        {idea.recommendations || ''}
                      </Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Paper>
      )}

      {activeTab === 1 && Number(idea?.score) >= 50 && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          {!buildPlan && !isGeneratingPlan ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography variant="h6" gutterBottom>
                Ready to build your idea?
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 3 }}>
                Get a detailed implementation plan with resource recommendations and next steps.
              </Typography>
              <Button
                variant="contained"
                onClick={handleBuildPlan}
                startIcon={isGeneratingPlan ? <CircularProgress size={20} /> : null}
              >
                Generate Build Plan
              </Button>
            </Box>
          ) : isGeneratingPlan ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CircularProgress size={40} sx={{ mb: 3 }} />
              <Typography variant="h6" gutterBottom>
                Building your plan...
              </Typography>
              <Typography 
                id="buildPlanLoadingMessage" 
                color="text.secondary"
              >
                Generating a customized implementation plan for your idea. This may take a moment.
              </Typography>
            </Box>
          ) : buildPlan ? (
            <Grid container spacing={3} sx={{ mt: 1 }}>
              {/* MVP Scope Section - Full width always */}
              {buildPlan.mvp_scope && (
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      bgcolor: 'background.default',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      color="primary.main"
                      gutterBottom
                      sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                    >
                      MVP Scope
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {buildPlan.mvp_scope}
                    </Typography>
                  </Paper>
                </Grid>
              )}

              {/* Calculate how many sections we have to adjust the layout */}
              {(() => {
                // Count how many grid sections we'll be showing
                const hasResources = buildPlan.required_resources && (
                  Array.isArray(buildPlan.required_resources) ? buildPlan.required_resources.length > 0 : 
                  typeof buildPlan.required_resources === 'object' ? Object.keys(buildPlan.required_resources).length > 0 : 
                  buildPlan.required_resources
                );
                
                const hasTechStack = buildPlan.tech_stack;
                const hasNextSteps = buildPlan.next_steps && buildPlan.next_steps.length > 0;
                const hasTimeline = buildPlan.estimated_timeline;
                
                // Count visible sections
                const visibleSections = [hasResources, hasTechStack, hasNextSteps, hasTimeline].filter(Boolean).length;
                
                // Create an array of the sections we'll show
                const sections = [];
                if (hasResources) sections.push('resources');
                if (hasTechStack) sections.push('techStack');
                if (hasNextSteps) sections.push('nextSteps');
                if (hasTimeline) sections.push('timeline');
                
                // For odd numbers of sections, make the last one full width
                const isOdd = visibleSections % 2 !== 0;
                
                return (
                  <>
                    {/* Required Resources Section */}
                    {hasResources && (
                      <Grid item xs={12} md={
                        visibleSections === 1 ? 12 : 
                        (isOdd && sections.indexOf('resources') === visibleSections - 1) ? 12 : 6
                      }>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            height: '100%',
                            bgcolor: 'background.default',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            color="primary.main"
                            gutterBottom
                            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                          >
                            Required Resources
                          </Typography>
                          {Array.isArray(buildPlan.required_resources) ? (
                            <Box component="ul" sx={{ mt: 1, pl: 2, mb: 0 }}>
                              {buildPlan.required_resources.map((resource, i) => (
                                <Typography component="li" key={i} variant="body2">{resource}</Typography>
                              ))}
                            </Box>
                          ) : typeof buildPlan.required_resources === 'object' ? (
                            <Box>
                              {/* Handle nested object structure */}
                              {Object.entries(buildPlan.required_resources).map(([category, items]) => (
                                <Box key={category} sx={{ mb: 2 }}>
                                  <Typography variant="subtitle2" fontWeight={600}>{category}</Typography>
                                  {Array.isArray(items) ? (
                                    <Box component="ul" sx={{ mt: 1, pl: 2 }}>
                                      {items.map((item, i) => (
                                        <Typography component="li" key={i} variant="body2">{item}</Typography>
                                      ))}
                                    </Box>
                                  ) : (
                                    <Typography variant="body2">{items}</Typography>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <Typography variant="body2">{buildPlan.required_resources}</Typography>
                          )}
                        </Paper>
                      </Grid>
                    )}

                    {/* Tech Stack Section */}
                    {hasTechStack && (
                      <Grid item xs={12} md={
                        visibleSections === 1 ? 12 : 
                        (isOdd && sections.indexOf('techStack') === visibleSections - 1) ? 12 : 6
                      }>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            height: '100%',
                            bgcolor: 'background.default',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            color="primary.main"
                            gutterBottom
                            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                          >
                            Tech Stack
                          </Typography>
                          <Box sx={{ display: 'grid', gap: 1 }}>
                            {buildPlan.tech_stack.frontend && buildPlan.tech_stack.frontend.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" fontWeight={600}>Frontend</Typography>
                                <Box component="ul" sx={{ mt: 0.5, pl: 2, mb: 1 }}>
                                  {buildPlan.tech_stack.frontend.map((tech, i) => (
                                    <Typography component="li" key={i} variant="body2">{tech}</Typography>
                                  ))}
                                </Box>
                              </Box>
                            )}
                            {buildPlan.tech_stack.backend && buildPlan.tech_stack.backend.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" fontWeight={600}>Backend</Typography>
                                <Box component="ul" sx={{ mt: 0.5, pl: 2, mb: 1 }}>
                                  {buildPlan.tech_stack.backend.map((tech, i) => (
                                    <Typography component="li" key={i} variant="body2">{tech}</Typography>
                                  ))}
                                </Box>
                              </Box>
                            )}
                            {buildPlan.tech_stack.database && buildPlan.tech_stack.database.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" fontWeight={600}>Database</Typography>
                                <Box component="ul" sx={{ mt: 0.5, pl: 2, mb: 1 }}>
                                  {buildPlan.tech_stack.database.map((tech, i) => (
                                    <Typography component="li" key={i} variant="body2">{tech}</Typography>
                                  ))}
                                </Box>
                              </Box>
                            )}
                            {buildPlan.tech_stack.infrastructure && buildPlan.tech_stack.infrastructure.length > 0 && (
                              <Box>
                                <Typography variant="subtitle2" fontWeight={600}>Infrastructure</Typography>
                                <Box component="ul" sx={{ mt: 0.5, pl: 2, mb: 1 }}>
                                  {buildPlan.tech_stack.infrastructure.map((tech, i) => (
                                    <Typography component="li" key={i} variant="body2">{tech}</Typography>
                                  ))}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Paper>
                      </Grid>
                    )}

                    {/* Next Steps Section */}
                    {hasNextSteps && (
                      <Grid item xs={12} md={
                        visibleSections === 1 ? 12 : 
                        (isOdd && sections.indexOf('nextSteps') === visibleSections - 1) ? 12 : 6
                      }>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            height: '100%',
                            bgcolor: 'background.default',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            color="primary.main"
                            gutterBottom
                            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                          >
                            Next Steps
                          </Typography>
                          <Box component="ul" sx={{ mt: 1, pl: 2, mb: 0 }}>
                            {buildPlan.next_steps.map((step, i) => (
                              <Typography component="li" key={i} variant="body2">{step}</Typography>
                            ))}
                          </Box>
                        </Paper>
                      </Grid>
                    )}

                    {/* Estimated Timeline Section */}
                    {hasTimeline && (
                      <Grid item xs={12} md={
                        visibleSections === 1 ? 12 : 
                        (isOdd && sections.indexOf('timeline') === visibleSections - 1) ? 12 : 6
                      }>
                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            height: '100%',
                            bgcolor: 'background.default',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          <Typography 
                            variant="h6" 
                            color="primary.main"
                            gutterBottom
                            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
                          >
                            Estimated Timeline
                          </Typography>
                          <Typography variant="body2">
                            {buildPlan.estimated_timeline}
                          </Typography>
                        </Paper>
                      </Grid>
                    )}
                  </>
                );
              })()}

              {/* If no sections are available, show a message */}
              {!buildPlan.mvp_scope && 
               !buildPlan.required_resources && 
               !buildPlan.tech_stack && 
               !(buildPlan.next_steps && buildPlan.next_steps.length > 0) && 
               !buildPlan.estimated_timeline && (
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      bgcolor: 'background.default',
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      textAlign: 'center'
                    }}
                  >
                    <Typography 
                      variant="body1" 
                      color="text.secondary"
                    >
                      No build plan details are available for this idea.
                    </Typography>
                  </Paper>
                </Grid>
              )}
              
              {/* Next Steps CTA */}
              <Grid item xs={12}>
                <Box 
                  sx={{ 
                    mt: 3, 
                    textAlign: 'center',
                    p: 3,
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText',
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'primary.main'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Ready to implement your idea?
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
                    Now that you have a complete build plan, it's time to bring your idea to life. 
                    Continue to the implementation phase to access resources and tools that will help you execute on your plan.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      setActiveTab(2);
                    }}
                    sx={{
                      py: 1.2,
                      px: 4,
                      fontWeight: 'bold',
                      borderRadius: 2,
                      boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                      '&:hover': {
                        boxShadow: '0 6px 15px rgba(0,0,0,0.25)',
                        transform: 'translateY(-2px)'
                      },
                      transition: 'all 0.2s ease'
                    }}
                  >
                    Continue to Implementation
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          )}
        </Paper>
      )}

      {/* NEW Implementation Tab */}
      {activeTab === 2 && Number(idea?.score) >= 50 && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 2, sm: 0 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden'
          }}
        >
          {!buildPlan ? (
            // Show a prompt to generate a build plan if it doesn't exist yet
            <Box sx={{ p: 4, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Let's Create Your Implementation Plan
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3, maxWidth: 600, mx: 'auto' }}>
                To help you implement your idea most effectively, we recommend generating a Build Plan first. 
                This will give you a customized roadmap with technical requirements, resource needs, and next steps.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleBuildPlan}
                disabled={isGeneratingPlan}
                startIcon={isGeneratingPlan ? <CircularProgress size={20} /> : null}
                sx={{ 
                  py: 1.2, 
                  px: 4,
                  borderRadius: 2,
                  fontWeight: 'bold' 
                }}
              >
                {isGeneratingPlan ? 'Generating...' : 'Generate Build Plan'}
              </Button>
              <Box sx={{ mt: 4, pt: 4, borderTop: '1px solid', borderColor: 'divider' }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Looking for freelance talent?
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  You can still explore professional freelance services on Fiverr while we prepare your build plan.
                </Typography>
                <a 
                  href="https://go.fiverr.com/visit/?bta=1106227&nci=17037" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block' }}
                >
                  <img 
                    src="https://fiverr.ck-cdn.com/tn/serve/?cid=40236346" 
                    width="100%" 
                    height="auto" 
                    alt="Fiverr Services" 
                    style={{ 
                      maxWidth: '641px',
                      borderRadius: '8px',
                      transition: 'transform 0.3s ease',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                    }}
                  />
                </a>
              </Box>
            </Box>
          ) : (
            // Show regular implementation content if build plan exists
            <>
              {/* Implementation Actions Section */}
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    overflow: 'hidden',
                    borderRadius: 3,
                    border: '1px solid',
                    borderColor: 'primary.main',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                      transform: 'translateY(-4px)'
                    }
                  }}
                >
                  {/* Hero Banner Section */}
                  <Box
                    sx={{
                      background: 'linear-gradient(135deg, #3f51b5 0%, #5c6bc0 100%)',
                      color: 'white',
                      p: { xs: 3, sm: 5 },
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    {/* Background Pattern */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%23ffffff\' fill-opacity=\'0.1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
                        opacity: 0.2,
                        zIndex: 0
                      }}
                    />
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 800,
                          mb: 2,
                          textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          fontSize: { xs: '1.75rem', sm: '2.25rem' }
                        }}
                      >
                        Ready to Bring Your Idea to Life?
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 3,
                          fontWeight: 400,
                          opacity: 0.9,
                          maxWidth: '800px',
                          fontSize: { xs: '1rem', sm: '1.25rem' }
                        }}
                      >
                        You're on the right track! Take the next step by turning your plan into action with these powerful implementation tools.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: 2,
                          mt: 3
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={handleOpenTechTalentModal}
                          sx={{
                            borderRadius: 2,
                            py: 1.5,
                            px: 3,
                            fontWeight: 'bold',
                            boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                            '&:hover': {
                              transform: 'translateY(-2px)',
                              boxShadow: '0 6px 15px rgba(0,0,0,0.25)'
                            },
                            transition: 'all 0.2s ease'
                          }}
                        >
                          Find Technical Talent
                        </Button>
                        <Button
                          component="a"
                          href="https://go.fiverr.com/visit/?bta=1106227&nci=17037"
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="contained"
                          size="large"
                          sx={{
                            borderRadius: 2,
                            py: 1.5,
                            px: 3,
                            fontWeight: 'bold',
                            bgcolor: '#1dbf73', // Fiverr green
                            '&:hover': {
                              bgcolor: '#19a463', // Darker Fiverr green
                              transform: 'translateY(-2px)',
                              boxShadow: '0 6px 15px rgba(0,0,0,0.25)'
                            },
                            transition: 'all 0.2s ease'
                          }}
                        >
                          Find Freelancers on Fiverr
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleOpenProjectBoardModal}
                          size="large"
                          sx={{
                            borderRadius: 2,
                            py: 1.5,
                            px: 3,
                            fontWeight: 'bold',
                            borderColor: 'rgba(255,255,255,0.7)',
                            color: 'white',
                            '&:hover': {
                              borderColor: 'white',
                              backgroundColor: 'rgba(255,255,255,0.1)'
                            }
                          }}
                        >
                          Create Project Board
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  {/* Action Buttons Section */}
                  <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 3,
                        fontWeight: 600,
                        textAlign: 'center',
                        position: 'relative',
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          bottom: -8,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: 60,
                          height: 3,
                          bgcolor: 'primary.main',
                          borderRadius: 1
                        }
                      }}
                    >
                      Implementation Options
                    </Typography>

                    <Grid container spacing={2.5} sx={{ mt: 2 }}>
                      {/* Get Launch Strategy */}
                      <Grid item xs={12} sm={6} md={6}>
                        <Paper
                          elevation={1}
                          sx={{
                            p: 2.5,
                            height: '100%',
                            borderRadius: 2,
                            transition: 'all 0.2s ease',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: 3,
                              transform: 'translateY(-4px)'
                            },
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Box
                              sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                bgcolor: 'rgba(63, 81, 181, 0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                color: 'primary.main'
                              }}
                            >
                              <Typography variant="h6">🚀</Typography>
                            </Box>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Get Launch Strategy
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 'auto' }}>
                            Receive a detailed go-to-market plan with timelines & marketing tactics for your product release
                          </Typography>
                          <Button
                            variant="text"
                            color="primary"
                            sx={{ alignSelf: 'flex-start', mt: 2 }}
                            onClick={handleOpenLaunchStrategyModal}
                          >
                            Generate Strategy
                          </Button>
                        </Paper>
                      </Grid>

                      {/* Enable Project Updates */}
                      <Grid item xs={12} sm={6} md={6}>
                        <Paper
                          elevation={1}
                          sx={{
                            p: 2.5,
                            height: '100%',
                            borderRadius: 2,
                            transition: 'all 0.2s ease',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: 3,
                              transform: 'translateY(-4px)'
                            },
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Box
                              sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                bgcolor: 'rgba(63, 81, 181, 0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 2,
                                color: 'primary.main'
                              }}
                            >
                              <Typography variant="h6">📊</Typography>
                            </Box>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Enable Project Updates
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 'auto' }}>
                            Get regular email or SMS reminders with VC-style accountability to stay motivated & focused
                          </Typography>
                          <Button
                            variant="text"
                            color="primary"
                            sx={{ alignSelf: 'flex-start', mt: 2 }}
                            onClick={handleOpenUpdatesModal}
                          >
                            Set Up Updates
                          </Button>
                        </Paper>
                      </Grid>
                    </Grid>

                    {/* Premium Options Banner */}
                    <Paper
                      elevation={0}
                      sx={{
                        mt: 4,
                        p: 3,
                        bgcolor: 'rgba(63, 81, 181, 0.05)',
                        borderRadius: 2,
                        border: '1px dashed',
                        borderColor: 'primary.main'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2
                        }}
                      >
                        <Box 
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            justifyContent: 'space-between',
                            gap: 2
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                              Premium Implementation Services
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Get personalized guidance from industry experts to accelerate your idea to market
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1.5, flexWrap: 'wrap' }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={handleConnectWithInvestors}
                              sx={{
                                borderRadius: 6,
                                px: 2,
                                fontWeight: 'medium',
                                '&:hover': {
                                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                                }
                              }}
                            >
                              Connect with Investors
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={handleGetFounderMentorship}
                              sx={{
                                borderRadius: 6,
                                px: 2,
                                fontWeight: 'medium',
                                '&:hover': {
                                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                                }
                              }}
                            >
                              Get Founder Mentorship
                            </Button>
                          </Box>
                        </Box>
                        
                        {/* Fiverr Affiliate Banner */}
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                            Find Top-Rated Freelancers on Fiverr
                          </Typography>
                          <a 
                            href="https://go.fiverr.com/visit/?bta=1106227&nci=17037" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            style={{ display: 'block' }}
                          >
                            <img 
                              src="https://fiverr.ck-cdn.com/tn/serve/?cid=40236346" 
                              width="100%" 
                              height="auto" 
                              alt="Fiverr Services" 
                              style={{ 
                                maxWidth: '100%',
                                borderRadius: '8px',
                                transition: 'transform 0.3s ease',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                              }}
                              onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.01)'}
                              onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                </Paper>
              </Grid>
            </>
          )}
        </Paper>
      )}

      {activeTab === 3 && Number(idea?.score) >= 75 && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: { xs: 3, sm: 4 },
            borderRadius: 3,
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" gutterBottom>
              Coming Soon!
            </Typography>
            <Typography color="text.secondary">
              The marketplace for selling ideas is under development.
            </Typography>
          </Box>
        </Paper>
      )}

      {/* Technical Talent Modal */}
      <Modal
        open={techTalentModalOpen}
        onClose={() => setTechTalentModalOpen(false)}
        aria-labelledby="tech-talent-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 600,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography id="tech-talent-modal-title" variant="h6" component="h2" gutterBottom>
            Find Technical Talent for Your Project
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            We'll help you create a job posting on your preferred platform to find the right talent for your idea.
          </Typography>
          
          <Box component="form" sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Choose Freelance Platform
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={preferredPlatform}
                onChange={(e) => setPreferredPlatform(e.target.value)}
                variant="outlined"
                size="small"
                helperText="We recommend Fiverr for the best selection of freelancers"
              >
                <MenuItem value="fiverr">Fiverr</MenuItem>
              </TextField>
            </Box>
            
            {/* Fiverr Affiliate Banner - Show only when Fiverr is selected */}
            {preferredPlatform === 'fiverr' && (
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <a 
                  href="https://go.fiverr.com/visit/?bta=1106227&nci=17037" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block' }}
                >
                  <img 
                    src="https://fiverr.ck-cdn.com/tn/serve/?cid=40236346" 
                    width="100%" 
                    height="auto" 
                    alt="Fiverr Services" 
                    style={{ 
                      maxWidth: '641px',
                      borderRadius: '8px',
                      transition: 'transform 0.3s ease',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                  />
                </a>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  Thousands of talented freelancers ready to help with your project
                </Typography>
              </Box>
            )}
            
            <Typography variant="subtitle2" gutterBottom>
              Job Description
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={6}
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                variant="outlined"
                placeholder="Describe what you need help with..."
              />
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Required Skills
            </Typography>
            {buildPlan?.tech_stack ? (
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                These skills are based on your project's tech stack
              </Typography>
            ) : (
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
                Select the skills needed for your project
              </Typography>
            )}
            <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {skillOptions.map((skill) => (
                <Button
                  key={skill}
                  variant={selectedSkills.includes(skill) ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleSkillToggle(skill)}
                  sx={{ 
                    mr: 1, 
                    mb: 1,
                    borderRadius: 5,
                    px: 1.5,
                    py: 0.5,
                    minWidth: 'unset',
                    textTransform: 'none',
                    fontSize: '0.8rem'
                  }}
                >
                  {skill}
                </Button>
              ))}
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Budget
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                value={jobBudget}
                onChange={(e) => setJobBudget(e.target.value)}
                variant="outlined"
                size="small"
                placeholder="e.g. $500 - $1,000"
              />
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setTechTalentModalOpen(false)}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={handleSubmitTechJob}
                disabled={isSubmittingJob || !jobDescription || selectedSkills.length === 0}
                startIcon={isSubmittingJob ? <CircularProgress size={16} /> : null}
              >
                {isSubmittingJob ? 'Submitting...' : 'Submit Job'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      
      {/* Launch Strategy Modal */}
      <Modal
        open={launchStrategyModalOpen}
        onClose={() => setLaunchStrategyModalOpen(false)}
        aria-labelledby="launch-strategy-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 600,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography id="launch-strategy-modal-title" variant="h6" component="h2" gutterBottom>
            Generate Launch Strategy
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            We'll create a customized go-to-market strategy to help you successfully launch your product.
          </Typography>
          
          <Box component="form" sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Target Market
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={targetMarket}
                onChange={(e) => setTargetMarket(e.target.value)}
                variant="outlined"
                size="small"
                helperText="Select your primary target audience"
              >
                <MenuItem value="b2c">B2C (Consumers)</MenuItem>
                <MenuItem value="b2b">B2B (Businesses)</MenuItem>
                <MenuItem value="b2g">B2G (Government)</MenuItem>
                <MenuItem value="mixed">Mixed Audience</MenuItem>
              </TextField>
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Launch Goals
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
              Select all that apply to your launch
            </Typography>
            <Box sx={{ mb: 3, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {["Maximize Users", "Generate Revenue", "Get Feedback", "Attract Investors", "Build Brand", "Test Market Fit"].map((goal) => (
                <Button
                  key={goal}
                  variant={launchGoals.includes(goal) ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleLaunchGoalToggle(goal)}
                  sx={{ 
                    mr: 1, 
                    mb: 1,
                    borderRadius: 5,
                    px: 1.5,
                    py: 0.5,
                    textTransform: 'none',
                    fontSize: '0.8rem'
                  }}
                >
                  {goal}
                </Button>
              ))}
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Launch Timeline
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={launchTimeline}
                onChange={(e) => setLaunchTimeline(e.target.value)}
                variant="outlined"
                size="small"
              >
                <MenuItem value="1month">1 Month (Quick Launch)</MenuItem>
                <MenuItem value="3months">3 Months (Standard)</MenuItem>
                <MenuItem value="6months">6 Months (Comprehensive)</MenuItem>
                <MenuItem value="12months">1 Year (Enterprise-level)</MenuItem>
              </TextField>
            </Box>
            
            <Box sx={{ 
              p: 2, 
              mb: 3, 
              bgcolor: 'rgba(63, 81, 181, 0.08)', 
              borderRadius: 1,
              border: '1px solid rgba(63, 81, 181, 0.2)',
            }}>
              <Typography variant="body2">
                <strong>What you'll receive:</strong> A detailed launch strategy document including timeline, marketing channels, budget recommendations, key metrics, and tactical steps for a successful product launch.
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setLaunchStrategyModalOpen(false)}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={handleGenerateStrategy}
                disabled={isGeneratingStrategy || !targetMarket || launchGoals.length === 0}
                startIcon={isGeneratingStrategy ? <CircularProgress size={16} /> : null}
              >
                {isGeneratingStrategy ? 'Generating...' : 'Generate Strategy'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      
      {/* Project Updates Modal */}
      <Modal
        open={updatesModalOpen}
        onClose={() => setUpdatesModalOpen(false)}
        aria-labelledby="project-updates-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 600,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography id="project-updates-modal-title" variant="h6" component="h2" gutterBottom>
            Enable Project Updates
          </Typography>
          
          {/* Coming Soon Banner */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 2, 
              mb: 3, 
              bgcolor: '#FFF9C4', // Lighter yellow background
              borderRadius: 1,
              border: '1px solid #FBC02D',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Box component="span" sx={{ fontSize: '1.5rem' }}>🚧</Box>
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ color: 'rgba(0,0,0,0.85)' }}>
                Coming Soon!
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.8)' }}>
                We're currently building this feature. You can explore the options, but enabling updates is not available yet.
              </Typography>
            </Box>
          </Paper>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Receive regular reminders and progress updates to keep your project on track and maintain momentum.
          </Typography>
          
          <Box component="form" sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Update Frequency
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={updateFrequency}
                onChange={(e) => setUpdateFrequency(e.target.value)}
                variant="outlined"
                size="small"
                helperText="How often would you like to receive updates?"
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="biweekly">Bi-weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </TextField>
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Notification Method
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={notificationMethod}
                onChange={(e) => setNotificationMethod(e.target.value)}
                variant="outlined"
                size="small"
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="sms">SMS</MenuItem>
                <MenuItem value="both">Both Email & SMS</MenuItem>
              </TextField>
            </Box>
            
            {/* Conditionally show phone input if SMS is selected */}
            {(notificationMethod === 'sms' || notificationMethod === 'both') && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Phone Number
                </Typography>
                <TextField
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  variant="outlined"
                  size="small"
                  placeholder="Enter your phone number"
                />
              </Box>
            )}
            
            <Typography variant="subtitle2" gutterBottom>
              Update Types
            </Typography>
            <Box sx={{ mb: 3 }}>
              <FormGroup>
                <MuiFormControlLabel
                  control={
                    <Checkbox 
                      checked={enabledUpdates.progressReminders} 
                      onChange={() => handleUpdateToggle('progressReminders')}
                    />
                  }
                  label="Progress Reminders"
                />
                <MuiFormControlLabel
                  control={
                    <Checkbox 
                      checked={enabledUpdates.milestoneAlerts} 
                      onChange={() => handleUpdateToggle('milestoneAlerts')}
                    />
                  }
                  label="Milestone Alerts"
                />
                <MuiFormControlLabel
                  control={
                    <Checkbox 
                      checked={enabledUpdates.resourceSuggestions} 
                      onChange={() => handleUpdateToggle('resourceSuggestions')}
                    />
                  }
                  label="Resource Suggestions"
                />
                <MuiFormControlLabel
                  control={
                    <Checkbox 
                      checked={enabledUpdates.marketUpdates} 
                      onChange={() => handleUpdateToggle('marketUpdates')}
                    />
                  }
                  label="Market Updates & Trends"
                />
              </FormGroup>
            </Box>
            
            <Box sx={{ 
              p: 2, 
              mb: 3, 
              bgcolor: 'rgba(63, 81, 181, 0.08)', 
              borderRadius: 1,
              border: '1px solid rgba(63, 81, 181, 0.2)',
            }}>
              <Typography variant="body2">
                <strong>Why enable updates?</strong> Studies show that projects with regular check-ins are 3x more likely to be completed. Our updates provide accountability, relevant resources, and keep you motivated throughout your implementation journey.
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setUpdatesModalOpen(false)}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                disabled={true} // Always disabled since feature is coming soon
                sx={{ 
                  position: 'relative',
                  '&::after': {
                    content: '"Coming Soon"',
                    position: 'absolute',
                    top: -20,
                    right: -10,
                    bgcolor: '#FFF9C4', // Lighter yellow background
                    color: 'rgba(0,0,0,0.85)', // Darker text color
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                    py: 0.3,
                    px: 0.8,
                    borderRadius: 1,
                    transform: 'rotate(3deg)',
                    border: '1px solid #FBC02D'
                  }
                }}
              >
                Enable Updates
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      
      {/* Project Board Modal */}
      <Modal
        open={projectBoardModalOpen}
        onClose={() => setProjectBoardModalOpen(false)}
        aria-labelledby="project-board-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 600,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflow: 'auto'
        }}>
          <Typography id="project-board-modal-title" variant="h6" component="h2" gutterBottom>
            Create Project Board
          </Typography>
          
          {/* Coming Soon Banner */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 2, 
              mb: 3, 
              bgcolor: '#FFF9C4', // Lighter yellow background
              borderRadius: 1,
              border: '1px solid #FBC02D',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Box component="span" sx={{ fontSize: '1.5rem' }}>🚧</Box>
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" sx={{ color: 'rgba(0,0,0,0.85)' }}>
                Coming Soon!
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.8)' }}>
                Our project board integration is currently in development. You can explore the options, but creation is not available yet.
              </Typography>
            </Box>
          </Paper>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            We'll set up a project management board to help you track and organize your implementation tasks.
          </Typography>
          
          <Box component="form" sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Choose Platform
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                select
                fullWidth
                value={projectBoardPlatform}
                onChange={(e) => setProjectBoardPlatform(e.target.value)}
                variant="outlined"
                size="small"
                helperText="Select your preferred project management tool"
              >
                <MenuItem value="trello">Trello</MenuItem>
                <MenuItem value="jira">Jira</MenuItem>
                <MenuItem value="asana">Asana</MenuItem>
                <MenuItem value="notion">Notion</MenuItem>
                <MenuItem value="github">GitHub Projects</MenuItem>
              </TextField>
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Project Name
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                variant="outlined"
                size="small"
                placeholder="Enter a name for your project"
              />
            </Box>
            
            <Typography variant="subtitle2" gutterBottom>
              Project Description
            </Typography>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                variant="outlined"
                placeholder="Describe the purpose and goals of your project..."
              />
            </Box>
            
            <Box sx={{ 
              p: 2, 
              mb: 3, 
              bgcolor: 'rgba(63, 81, 181, 0.08)', // Lighter yellow background
              borderRadius: 1,
              border: '1px solid rgba(63, 81, 181, 0.2)',
            }}>
              <Typography variant="body2">
                <strong>What you'll receive:</strong> A fully configured project board with pre-populated tasks based on your build plan, including milestones, deadlines, and resource allocation recommendations.
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => setProjectBoardModalOpen(false)}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={handleCreateProjectBoard}
                disabled={true} // Always disabled since feature is coming soon
                startIcon={isCreatingBoard ? <CircularProgress size={16} /> : null}
                sx={{ 
                  position: 'relative',
                  '&::after': {
                    content: '"Coming Soon"',
                    position: 'absolute',
                    top: -20,
                    right: -10,
                    bgcolor: '#FFF9C4', // Lighter yellow background
                    color: 'rgba(0,0,0,0.85)', // Darker text color
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                    py: 0.3,
                    px: 0.8,
                    borderRadius: 1,
                    transform: 'rotate(3deg)',
                    border: '1px solid #FBC02D'
                  }
                }}
              >
                Create Project
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {isMobile && <MobileBottomNavigation currentValue="progress" />}
    </Box>
  );
}

export default IdeaProgress;