import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Badge,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import SwipeIcon from '@mui/icons-material/SwipeRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

/**
 * Shared mobile bottom navigation component to be used across the app
 * @param {Object} props - Component props
 * @param {string} props.currentValue - The current active value
 * @param {Function} props.onHomeClick - Optional callback for home action
 * @param {Function} props.onSubmitClick - Optional callback for submit action
 */
function MobileBottomNavigation({ 
  currentValue, 
  onHomeClick,
  onSubmitClick
}) {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Detect if app is in standalone mode (added to home screen)
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if app is running in standalone mode (added to home screen)
    const isIOSStandalone = window.navigator.standalone === true;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
  }, []);
  
  // Don't render on non-mobile devices
  if (!isMobile) return null;
  
  const handleChange = (event, newValue) => {
    // Direct navigation based on value
    if (newValue === 'home') {
      if (onHomeClick) {
        onHomeClick();
      } else {
        navigate('/');
      }
    } else if (newValue === 'submit') {
      if (onSubmitClick) {
        onSubmitClick();
      } else {
        navigate('/?tab=submit');
      }
    } else if (newValue === 'dashboard') {
      navigate('/dashboard');
    } else if (newValue === 'profile') {
      navigate('/profile');
    } else if (newValue === 'login' && !isAuthenticated) {
      navigate('/login');
    } else if (newValue === 'register' && !isAuthenticated) {
      navigate('/register');
    }
  };
  
  return (
    <BottomNavigation
      value={currentValue}
      onChange={handleChange}
      showLabels
      className="mobile-bottom-nav"
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1500,
        height: 'auto',
        minHeight: '64px',
        paddingBottom: 'env(safe-area-inset-bottom, 16px)',
        boxShadow: '0px -4px 10px rgba(0,0,0,0.2)',
        bgcolor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        '& .MuiBottomNavigationAction-root': {
          minWidth: 'auto',
          color: 'text.secondary',
          paddingTop: 1,
          paddingBottom: isStandalone 
            ? 'env(safe-area-inset-bottom, 0px)'
            : 'calc(env(safe-area-inset-bottom, 0px) + 4px)',
          '&.Mui-selected': {
            color: 'primary.main',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.7rem',
            marginTop: '2px'
          }
        }
      }}
    >
      <BottomNavigationAction 
        label="Explore" 
        icon={<SwipeIcon />} 
        value="home"
      />
      <BottomNavigationAction 
        label="Submit" 
        icon={<LightbulbIcon />} 
        value="submit"
      />
      
      {/* Show Dashboard button only for authenticated users */}
      {isAuthenticated && (
        <BottomNavigationAction 
          label="Dashboard" 
          icon={<DashboardIcon />} 
          value="dashboard"
        />
      )}
      
      {/* Show Profile button for authenticated users, otherwise show Login */}
      {isAuthenticated ? (
        <BottomNavigationAction 
          label="Profile" 
          icon={
            user?.balance ? (
              <Badge
                badgeContent={(Math.round(user.balance * 100) / 100).toFixed(2)}
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#FFD700',
                    color: '#000',
                    fontSize: '0.6rem',
                    minWidth: '18px',
                    height: '18px',
                  }
                }}
              >
                <AccountCircleIcon />
              </Badge>
            ) : (
              <AccountCircleIcon />
            )
          } 
          value="profile"
        />
      ) : (
        <BottomNavigationAction 
          label="Login" 
          icon={<AccountCircleIcon />} 
          value="login"
        />
      )}
    </BottomNavigation>
  );
}

export default MobileBottomNavigation; 