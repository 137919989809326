// src/services/api.js

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/api'
});

export const submitIdea = async (content) => {
    try {
        // Make sure the authentication token is fresh
        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {})
        };
        
        const response = await fetch('/api/ideas/', {
            method: 'POST',
            headers,
            body: JSON.stringify({ content }) // This matches the IdeaCreate model in the backend
        });

        const data = await response.text();
        let parsedData;
        try {
            parsedData = JSON.parse(data);
        } catch (e) {
            // If response is not JSON, use text as error message
            throw new Error(data || 'Failed to submit idea');
        }

        if (!response.ok) {
            // Handle specific error cases
            if (response.status === 402) {
                throw new Error(`Insufficient balance. ${parsedData.detail}`);
            } else if (response.status === 401) {
                throw new Error('Please log in to submit an idea');
            } else if (response.status === 429) {
                throw new Error('Too many requests. Please try again later');
            } else if (response.status === 400) {
                throw new Error(parsedData.detail || 'Invalid idea submission. Please check your input.');
            } else if (response.status === 413) {
                throw new Error('Your idea is too long. Please make it shorter.');
            } else {
                throw new Error(parsedData.detail || 'Failed to submit idea');
            }
        }

        return parsedData;
    } catch (error) {
        // Log the error for debugging
        console.error('Error submitting idea:', error);
        
        // Rethrow with user-friendly message
        if (error.message.includes('Failed to fetch')) {
            throw new Error('Network error. Please check your internet connection and try again.');
        }
        throw new Error(error.message || 'Failed to submit idea. Please try again later.');
    }
};

export const getProgress = async (ideaId) => {
    const response = await axiosInstance.get(`/progress/${ideaId}`);
    return response.data;
};

export const api = {
    submitIdea,
    getProgress,
};